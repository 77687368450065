import React from "react";

function DedicatedHomeWarrantyServicesIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="78"
            height="78"
            fill="none"
            viewBox="0 0 78 78"
        >
            <g fill="#000" clipPath="url(#clip0_1_963)">
                <path d="M37.489 57.265l-4.34-4.339-1.722 1.723 5.2 5.2c.244.244.553.357.861.357.31 0 .618-.114.862-.357l7.913-7.914-1.722-1.723-7.052 7.053z"></path>
                <path d="M57.85 39.439c-12.204 0-18.85-6.565-18.85-6.565s-6.646 6.565-18.85 6.565V53.82c0 11.7 7.28 18.98 18.411 23.205.146.049.293.114.439.162.146-.048.292-.113.439-.162 11.147-4.225 18.41-11.521 18.41-23.205V39.439zM39 66.983c-6.598 0-11.96-5.347-11.96-11.96 0-6.598 5.346-11.96 11.96-11.96 6.597 0 11.96 5.346 11.96 11.96 0 6.613-5.363 11.96-11.96 11.96zM66.202 2.031c0-.666-.552-1.219-1.218-1.219H58.06c-.666 0-1.219.553-1.219 1.22v8.547l9.36 7.166V2.031z"></path>
                <path d="M77.155 30.826a4.446 4.446 0 00-1.69-2.925L41.68 2.047a4.437 4.437 0 00-5.362 0L2.535 27.901a4.423 4.423 0 00-.829 6.192c1.479 1.933 4.258 2.29 6.191.812L39 11.099l31.102 23.806c.78.601 1.707.91 2.665.91.195 0 .39-.016.585-.032 1.17-.163 2.21-.748 2.925-1.69a4.448 4.448 0 00.878-3.267z"></path>
                <path d="M67.129 35.701V64.53a2.254 2.254 0 01-2.26 2.258h-8.027a22.337 22.337 0 01-1.673 2.438h9.7a4.709 4.709 0 004.697-4.696V37.456a8.714 8.714 0 01-.959-.617L67.13 35.7zM13.114 66.787a2.254 2.254 0 01-2.259-2.258V35.718l-1.479 1.137a8.71 8.71 0 01-.959.617v27.073a4.709 4.709 0 004.697 4.696h9.701a22.352 22.352 0 01-1.674-2.437h-8.027v-.017z"></path>
            </g>
            <defs>
                <clipPath id="clip0_1_963">
                    <path fill="#fff" d="M0 0H78V78H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default DedicatedHomeWarrantyServicesIcon;
