import React from "react";

function IncreasedBrandAwarenessIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            fill="none"
            viewBox="0 0 52 52"
        >
            <rect
                width="50.5"
                height="50.5"
                x="0.75"
                y="0.75"
                stroke="#000"
                strokeWidth="1.5"
                rx="15.25"
            ></rect>
            <path
                fill="#000"
                d="M34.32 16.004a.657.657 0 00-.103.021l-17.07 4.922c-1.358.015-2.593.405-3.53 1.08-.958.69-1.617 1.716-1.617 2.873 0 1.157.66 2.173 1.616 2.863.828.595 1.887.978 3.058 1.07l1.606 7.084a.68.68 0 00.638.515h3.624c.405.003.746-.444.638-.834l-1.43-5.426 12.467 3.593c.39.114.841-.222.844-.628V16.663c.003-.374-.37-.705-.742-.659zm-.577 1.534v14.714l-1.318-.381V17.92l1.318-.382zm6.197.104a.663.663 0 00-.288.102l-2.635 1.648a.687.687 0 00-.211.911.687.687 0 00.91.211l2.636-1.647a.68.68 0 00.297-.782.68.68 0 00-.709-.444zm-8.832.658v13.19l-13.178-3.8V22.1l13.178-3.8zM16.57 22.357v5.076c-.853-.105-1.629-.343-2.183-.741-.698-.504-1.07-1.13-1.07-1.792 0-.662.372-1.298 1.07-1.802.554-.398 1.33-.636 2.183-.74zm20.734 1.555a.685.685 0 00-.628.69.686.686 0 00.69.628h2.965a.685.685 0 00.668-.66.685.685 0 00-.668-.658h-3.027zm.03 4.602a.678.678 0 00-.62.493.678.678 0 00.302.732l2.635 1.648c.294.181.726.08.909-.213a.687.687 0 00-.209-.91l-2.635-1.647a.664.664 0 00-.381-.103zm-19.261.597l2.203.628 1.41 5.375h-2.244l-1.369-6.003z"
            ></path>
        </svg>
    );
}

export default IncreasedBrandAwarenessIcon;
