import React from "react";

function TrustTransparencyIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            fill="none"
            viewBox="0 0 52 52"
        >
            <rect
                width="50.5"
                height="50.5"
                x="0.75"
                y="0.75"
                stroke="#000"
                strokeWidth="1.5"
                rx="15.25"
            ></rect>
            <path
                fill="#000"
                d="M25.256 35.876h7.874c.59 0 1.708.142 1.708-.65l.02-2.247c.398 0 1.067-.052 1.067-.58 0-1.18.004-1.684.004-2.857.26 0 .465-.288.465-.536v-2.063c0-.248-.174-.661-.465-.661v-2.528c0-.764-1.158-.905-1.717-.905h-5.31a.861.861 0 01-.823-1.11l.783-3.87c.055-.28-.043-.918-.212-1.54-.107-.386-.249-1-.599-1.244-.39-.276-.968-.44-1.433-.339l-.67 3.233a.843.843 0 01-.208.413l-4.047 5.047v8.43c0 .799.13 1.637.508 2.35.633 1.2 1.767 1.657 3.055 1.657zm-5.28-11.882l-4.322-.027c-.878-.008-.934.197-.934 1.04v9.798c0 .784.028 1.071.867 1.071h4.39V23.994zm1.717 12.335v.41a.86.86 0 01-.858.858H15.587c-1.835 0-2.587-1.055-2.587-2.792v-9.799c0-1.783.791-2.76 2.661-2.748l4.764.028 3.882-4.843.642-3.208c.043-.229.15-.437.299-.61.945-1.08 3.012-.599 4 .224l.059.047c.402.354.764 1.154.992 1.98.232.847.346 1.796.24 2.327l-.59 2.925h4.263c1.618 0 3.433.756 3.433 2.626v.437c0 .406-.055.768-.18 1.079a2.5 2.5 0 01.645 1.673v2.063a2.23 2.23 0 01-.665 1.595c.145.374.197.819.197 1.295v.504c0 .65-.26 1.276-.823 1.72l-.303.205c.05.3.039.602.039.902 0 2.118-1.74 2.37-3.425 2.37h-7.874c-1.303 0-2.587-.378-3.563-1.268z"
            ></path>
        </svg>
    );
}

export default TrustTransparencyIcon;
