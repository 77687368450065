import React, { ReactElement } from "react";
import Image from "next/image";
const list = [
    {
        img: "/premiumcoverage/assets/images/joinOurPlatformImg.png",
        title: "Join Our Platform:",
        description:
            "As a partner, your home warranty plans will be featured prominently in our listings, allowing homeowners to easily compare your offerings alongside other providers",
        deferentStyle: false,
    },
    {
        img: "/premiumcoverage/assets/images/customizeYourPlansImg.png",
        title: "Customize Your Plans:",
        description:
            "Showcase the unique features of your home warranty options and highlight what sets you apart from the competition",
        deferentStyle: true,
    },
    {
        img: "/premiumcoverage/assets/images/seamlessCheckoutExperienceImg.png",
        title: "Seamless Checkout Experience:",
        description:
            "Our platform doesn't just generate leads; we handle the entire sales process for you. Homeowners can easily browse, compare, and purchase your plans directly through our system, delivering completed sales to your business",
        deferentStyle: false,
    },
    {
        img: "/premiumcoverage/assets/images/weHandleSalesImg.png",
        title: "We Handle the Sales:",
        description:
            "We offer an intuitive and simple checkout process, allowing customers to browse, compare, and purchase plans easily",
        deferentStyle: true,
    },
];
export default function HowItWorks(): ReactElement {
    return (
        <section className=" mt-12 lg:mt-24 mb-32 lg:mb-52">
            <h2 className="text-2xl lg:text-5xl lg:leading-[55px] font-extrabold">
                How It Works :
            </h2>

            <div className="mt-8 lg:mt-16">
                {list.map((el, index) => (
                    <div
                        key={el.title}
                        className={`flex flex-col lg:flex-row  mt-16 lg:mt-36 first-of-type:mt-0 lg:items-center gap-3 lg:gap-0 ${
                            el.deferentStyle ? "lg:justify-end" : ""
                        }`}
                    >
                        <div
                            className={`relative w-[322px] h-[240px] lg:w-[447px] lg:h-[339px]  ${
                                el.deferentStyle ? "lg:order-2" : "lg:order-1"
                            }`}
                        >
                            <Image src={el.img} layout="fill" alt={el.title} />
                        </div>
                        <div
                            className={`${
                                el.deferentStyle
                                    ? "lg:order-1 lg:mr-14"
                                    : "lg:order-2 lg:ml-14"
                            }  max-w-[322px] lg:max-w-[400px] flex flex-col justify-center `}
                        >
                            <span
                                className="block text-[40px] lg:text-[80px] font-light  lg:leading-[65px]"
                                style={{ color: "#FB6C05" }}
                            >{`0${index + 1}`}</span>
                            <h3 className="font-bold text-[22px] lg:text-[26px] mt-2">
                                {el.title}
                            </h3>
                            <p
                                className="font-light text-sm lg:text-[20px] mt-1 leading-6 lg:leading-8"
                                style={{ color: "#4C4C4C" }}
                            >
                                {el.description}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}
