import React from "react";

function SeamlessUserExperienceIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="78"
            height="78"
            fill="none"
            viewBox="0 0 78 78"
        >
            <g clipPath="url(#clip0_1_961)">
                <path
                    fill="#000"
                    fillRule="evenodd"
                    d="M19.382 38.995c0 10.818 8.8 19.622 19.62 19.622 10.818 0 19.617-8.804 19.617-19.622 0-10.817-8.8-19.611-19.618-19.611-10.818 0-19.619 8.793-19.619 19.611zm47.784 7.738A38.944 38.944 0 0139 75.52a38.949 38.949 0 01-28.168-28.786V12.19h15.771c.12-.002.239-.02.354-.054A22.98 22.98 0 0039 3.429a22.963 22.963 0 0012.042 8.707c.114.034.233.052.352.054h15.77v34.543zM51.578 9.753h16.807a1.218 1.218 0 011.218 1.218v35.904c0 .091-.01.182-.031.272a41.553 41.553 0 01-30.273 30.82c-.197.044-.4.044-.597 0a41.552 41.552 0 01-30.274-30.82c-.02-.09-.031-.18-.032-.272V10.97a1.217 1.217 0 011.22-1.219H26.42c4.777-1.49 8.867-4.734 11.536-9.163a1.22 1.22 0 012.087 0c2.667 4.43 6.76 7.672 11.534 9.163zM37.937 46.69a1.227 1.227 0 01-1.686.087l-8.87-7.63a1.22 1.22 0 111.59-1.85l7.98 6.867 11.981-12.853a1.22 1.22 0 011.784 1.666L37.936 46.69z"
                    clipRule="evenodd"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1_961">
                    <path fill="#fff" d="M0 0H78V78H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default SeamlessUserExperienceIcon;
