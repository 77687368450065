import React, { ReactElement } from "react";
import styles from "./style.module.scss";
import ExpandedMarketReachIcon from "./icons/expandedMarketReachIcon";
import GenerateMoreLeadsIcon from "./icons/generateMoreLeadsIcon";
import IncreasedBrandAwarenessIcon from "./icons/increasedBrandAwarenessIcon";
import CustomizableWarrantyOptionsIcon from "./icons/customizableWarrantyOptionsIcon";
import AutomatedBillingIcon from "./icons/automatedBillingIcon";
import IndustryRegulationsIcon from "./icons/industryRegulationsIcon";
import ProactiveCustomerEngagementIcon from "./icons/proactiveCustomerEngagementIcon";
import TrustTransparencyIcon from "./icons/trustTransparencyIcon";
const list = [
    {
        icon: <ExpandedMarketReachIcon />,
        title: "Expanded Market Reach:",
        description:
            "Access a wider audience through our established platform, connecting you with new customers effortlessly—no additional marketing efforts required",
    },
    {
        icon: <GenerateMoreLeadsIcon />,
        title: "Generate More Leads:",
        description:
            "Our platform enhances lead generation by enabling homeowners to easily compare various warranty options. By joining, your offerings will stand out as a top choice for reliable protection ",
    },
    {
        icon: <IncreasedBrandAwarenessIcon />,
        title: "Increased Brand Awareness:",
        description:
            "Our marketplace acts as a go-to destination for homeowners searching for home warranties, giving your company the visibility it deserves and positioning you in front of highly targeted customers",
    },
    {
        icon: <CustomizableWarrantyOptionsIcon />,
        title: "Customizable Warranty Options:",
        description:
            "You'll have the flexibility to customize how your plans are presented and bundled, aligning them with customer needs, seasonal promotions, or competitive pricing strategies to boost sales and maximize revenue",
    },
    {
        icon: <AutomatedBillingIcon />,
        title: "Automated Billing and Payment Processing:",
        description:
            "Our platform offers a secure, automated system for payment collection and subscription management, simplifying operations and minimizing administrative tasks while ensuring timely, reliable transactions.",
    },
    {
        icon: <IndustryRegulationsIcon />,
        title: "Compliance with Industry Regulations:",
        description:
            "Our marketplace ensures all home warranty sales comply with industry regulations, including licensing, accurate pricing, and state-specific rules. We handle the compliance, so you can focus on connecting with customers while reducing legal risks",
    },
    {
        icon: <ProactiveCustomerEngagementIcon />,
        title: "Proactive Customer Engagement:",
        description:
            "We implement advanced customer retention strategies, including follow-up emails and loyalty programs, to drive renewals and upsell opportunities, ultimately boosting the lifetime value of your customers",
    },
    {
        icon: <TrustTransparencyIcon />,
        title: "Trust and Transparency:",
        description:
            "We offer homeowners clear, transparent comparisons, empowering them to make informed decisions that fit their needs. By participating, you build trust in your services and strengthen your reputation as a reliable provider",
    },
];
export default function WhyPartnerWithUs(): ReactElement {
    return (
        <section className={styles["bg-style"]}>
            <div className="flex  flex-col lg:flex-row pt-24 lg:pt-44 lg:pb-32 pb-16 gap-10 lg:gap-0">
                <div className="lg:max-w-[350px] ">
                    <h2 className="text-2xl lg:text-5xl lg:leading-[55px] font-extrabold">
                        Why Partner with Us?
                    </h2>
                    <p
                        className="text-sm lg:text-lg font-light mt-3 lg:mt-7"
                        style={{ color: "#464646" }}
                    >
                        Our platform is designed to seamlessly connect home
                        warranty companies with homeowners. By joining
                        PremiumCoverage.com, you'll benefit from:
                    </p>
                </div>
                <div className=" flex flex-wrap gap-20 lg:gap-10 lg:justify-center">
                    {list.map((el) => (
                        <div key={el.title} className="max-w-[350px] ">
                            <span>{el.icon}</span>
                            <h3 className="font-bold text-sm lg:text-lg mt-2 lg:mt-4">
                                {el.title}
                            </h3>
                            <p className="font-light text-sm lg:text-lg mt-2">
                                {el.description}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
