import React from "react";

function MainImg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 818 497"
        >
            <path
                fill="#EEE"
                d="M744.21 76.561l2.689-7.384-1.347-8.724 3.362-2.352-2.354-4.698 4.035-3.688v-22.82l6.379 5.367 3.696-3.353 10.08-2.013 4.704 11.41 7.726 13.422v6.712l5.038-3.024v7.722l3.695.334 3.358-4.364 8.399 12.082-2.35 2.686-4.034-4.699-3.357 7.384.334 5.033-6.38 3.692-2.015 8.052-4.703-3.02-12.769 7.384 2.689 4.36-9.407 10.742-6.719-12.755-3.022-1.007-3.362-13.088-4.365-9.393z"
            ></path>
            <path
                fill="#fff"
                d="M761.489 114.422l-7.173-13.615-3.147-1.05-3.464-13.49-4.494-9.668 2.736-7.52-1.394-9.059 3.177-2.223-2.306-4.608 4.244-3.89V24.905l7.285 6.129 3.284-2.986 11.075-2.214 4.969 12.057 7.821 13.585v5.328l5.037-3.02v8.507l2.35.214 3.82-4.96 9.531 13.71-3.528 4.03-3.782-4.407-2.662 5.855.36 5.375-6.543 3.782-2.234 8.922-5.312-3.414-11.456 6.626 2.546 4.133-10.74 12.262v.004zm-8.781-16.095l2.899.964 6.264 11.889 8.069-9.213-2.83-4.59 14.076-8.143 4.095 2.63 1.796-7.183 6.226-3.597-.313-4.695 4.056-8.912 4.278 4.99 1.175-1.341-7.267-10.45-2.898 3.764-5.043-.458v-6.93l-5.037 3.02v-8.096l-7.658-13.32-4.412-10.707-9.081 1.816L757 33.491l-5.484-4.613v21.24l-3.816 3.495 2.397 4.788-3.541 2.476 1.29 8.39-2.637 7.247 4.262 9.208 3.233 12.6.004.005z"
            ></path>
            <path
                fill="#EEE"
                d="M744.211 76.56l-4.031-1.006-2.521 7.046 2.354 4.026.922 6.21-3.276 3.692-1.007 11.406v7.551l3.022 13.761 11.589-2.18 7.058-1.512 2.517-6.21-2.689-1.174 3.529-5.366-6.719-12.755-3.022-1.007-3.362-13.088-4.364-9.393z"
            ></path>
            <path
                fill="#fff"
                d="M738.967 130.317l-3.237-14.733v-7.731l1.037-11.714 3.19-3.598-.836-5.606-2.478-4.245 2.946-8.24 5.273 1.315 4.609 9.958 3.233 12.6 2.898.964 7.148 13.56-3.225 4.908 2.509 1.096-3.04 7.496-7.533 1.61-12.494 2.351v.009zm-1.389-14.93l2.808 12.784 10.71-2.017 6.552-1.405 1.998-4.921-2.869-1.255 3.829-5.829-6.29-11.941-3.147-1.05-3.464-13.49-4.146-8.918-2.791-.698-2.093 5.855 2.226 3.807 1.011 6.81-3.357 3.787-.977 11.067v7.414z"
            ></path>
            <path
                fill="#EEE"
                d="M737.659 82.6l-8.399 1.512h-5.878l-7.392 6.54v5.371l1.681 2.686-1.008 3.692 4.198 3.353-.669 8.558 6.633 6.711 1.766 9.731-.171 3.358 5.209-2.519 6.045-2.347-3.022-13.761v-7.551l1.007-11.405 3.276-3.692-.922-6.21-2.354-4.027z"
            ></path>
            <path
                fill="#fff"
                d="M727.421 135.619l.24-4.806-1.694-9.345-6.731-6.81.664-8.489-4.292-3.43 1.064-3.89-1.608-2.565v-6.047l7.966-7.05h6.148l8.953-1.61 2.765 4.736 1.012 6.81-3.357 3.786-.978 11.068v7.409l3.173 14.434-6.748 2.621-6.577 3.178zm-6.277-21.659l6.538 6.613 1.835 10.116-.094 1.906 3.872-1.867 5.307-2.065-2.877-13.084v-7.731l1.038-11.714 3.19-3.598-.836-5.606-1.938-3.315-7.928 1.41h-5.526l-6.817 6.034v4.69l1.753 2.8-.956 3.496 4.108 3.28-.678 8.626.009.009z"
            ></path>
            <path
                fill="#EEE"
                d="M728.419 134.111v11.243l8.233-1.512 15.79-4.111 6.719-2.433v3.692l5.539 4.364v4.193l2.187-1.34 2.35 3.353 3.022-3.353 1.681-7.551 5.711 2.18-1.681-13.59-6.718 5.872-7.22-3.859 2.349-6.377-2.688-1.679-2.856-3.859-2.516 6.21-7.058 1.512-11.589 2.18-6.045 2.347-5.21 2.518z"
            ></path>
            <path
                fill="#fff"
                d="M769.147 153.042l-2.517-3.593-2.851 1.751v-5.4l-5.544-4.365v-2.822l-5.561 2.008-15.855 4.125-9.321 1.709v-12.93l5.797-2.797 6.212-2.394 11.589-2.18 6.552-1.405 2.954-7.281 3.73 5.041 3.177 1.983-2.332 6.326 5.951 3.178 7.55-6.6 2.079 16.845-6.148-2.348-1.501 6.742-3.961 4.399v.008zm-2.003-6.077l2.187 3.118 2.084-2.313 1.861-8.356 5.273 2.013-1.277-10.339-5.887 5.143-8.494-4.54 2.371-6.424-2.199-1.375-1.981-2.677-2.084 5.136-7.533 1.61-11.529 2.171-5.934 2.305-4.652 2.248v9.556l7.104-1.302 15.714-4.091 7.919-2.865v4.561l5.544 4.365v2.989l1.518-.933h-.005z"
            ></path>
            <path
                fill="#EEE"
                d="M752.443 139.73l3.357 10.322 3.19 5.705 3.863 2.013-2.684-6.21 4.532-2.013v-4.193l-5.54-4.364v-3.692l-6.718 2.432z"
            ></path>
            <path
                fill="#fff"
                d="M764.735 159.792l-6.419-3.345-3.4-6.112-3.635-11.17 8.798-3.186v4.561l5.543 4.364v5.243l-4.249 1.884 3.357 7.757.005.004zm-5.072-4.729l1.307.681-2.015-4.659 4.824-2.142v-3.148l-5.544-4.364v-2.823l-4.639 1.679 3.052 9.384 3.015 5.384v.008z"
            ></path>
            <path
                fill="#EEE"
                d="M728.419 145.354l3.696 6.206-1.848 8.056 3.023 3.354 7.645-1.679 1.933-3.521 5.373-1.679 4.201-1.931 6.547 1.597-3.189-5.705-3.358-10.322-15.79 4.112-8.233 1.512z"
            ></path>
            <path
                fill="#fff"
                d="M732.964 163.989l-3.704-4.111 1.874-8.159-4.185-7.033 9.501-1.743 16.597-4.322 3.602 11.059 4.18 7.474-8.296-2.018-4.018 1.838-5.021 1.567-1.955 3.564-8.579 1.884h.004zm-1.689-4.634l2.341 2.6 6.706-1.473 1.908-3.483 5.672-1.768 4.438-2.039 4.802 1.169-2.23-4.022-3.091-9.491-15.011 3.906-6.924 1.272 3.207 5.388-1.822 7.945.004-.004z"
            ></path>
            <path
                fill="#EEE"
                d="M715.99 90.652L695.5 92.33l-11.422 16.022-1.848 5.624-5.711 3.52 5.878 1.846-1.342 14.768-16.459 3.187h-11.928l-4.704 3.859 3.696 2.852.506 4.364-4.202 7.885-5.372 4.532 3.357 6.039 24.692-5.2 25.867-7.551 5.376-2.518 4.365 4.531h3.863l-.167 5.033 12.764 3.191 5.753 2.514.798 3.859-3.19 3.525 2.35 3.019 3.361-3.019 4.871.334 1.342-4.026 11.173-5.2 8.146-5.037-2.354-2.514-8.227 3.859-13.442 1.341-3.022-3.354 1.847-8.056-3.695-6.206v-11.243l.171-3.358-1.766-9.731-6.633-6.711.669-8.557-4.198-3.354 1.008-3.692-1.681-2.685v-5.371z"
            ></path>
            <path
                fill="#fff"
                d="M728.3 178.582l-3.439-4.416 3.396-3.752-.605-2.933-5.243-2.296-13.416-3.349.163-4.827h-3.301l-4.181-4.339-4.909 2.287-25.935 7.568-25.357 5.341-4.06-7.298 5.818-4.904 3.979-7.474-.424-3.692-4.305-3.324 5.857-4.805h12.168l15.692-3.041 1.213-13.333-7.095-2.227 7.143-4.407 1.787-5.435 11.752-16.49 21.918-1.794v6.107l1.754 2.801-.956 3.495 4.107 3.281-.677 8.626 6.538 6.613 1.835 10.116-.171 3.439v10.965l3.756 6.309-1.823 7.945 2.389 2.651 12.815-1.281 8.695-4.077 3.58 3.829-9.201 5.679-10.809 5.032-1.445 4.331-5.192-.36-3.807 3.422-.004.017zm-1.025-4.326l1.265 1.628 2.911-2.617 4.549.312 1.243-3.726 11.482-5.345 7.143-4.411-1.123-1.204-7.765 3.641-14.058 1.404-3.658-4.06 1.874-8.159-3.636-6.107v-11.543l.163-3.251-1.693-9.345-6.732-6.81.665-8.489-4.292-3.431 1.063-3.889-1.608-2.565v-4.63l-19.062 1.559-11.092 15.56-1.908 5.808-4.279 2.638 4.661 1.465-1.475 16.198-17.317 3.336h-11.594l-3.55 2.913 3.087 2.381.583 5.033-4.416 8.296-4.93 4.154 2.658 4.78 23.988-5.054 25.764-7.521 5.908-2.766 4.554 4.724h4.424l-.175 5.242 12.189 3.058 6.191 2.707.991 4.784-2.984 3.298-.009.004z"
            ></path>
            <path
                fill="#EEE"
                d="M642.592 160.79l-9.746 7.718 1.848 2.853-.501 11.071 5.372 11.915-3.692 1.007 4.871 15.941h5.038l5.878-6.043 3.361 1.679 53.916-8.892 1.514-4.197 2.516 2.518 7.392-11.911-7.392-4.197-.84-8.724 5.209-8.558-7.391-1.846.167-5.032h-3.863l-4.365-4.532-5.376 2.519-25.867 7.551-24.692 5.199-3.357-6.039z"
            ></path>
            <path
                fill="#fff"
                d="M646.172 212.216h-6.114l-5.351-17.505 3.572-.972-5.021-11.127.502-10.999-2.144-3.306 11.255-8.918 3.558 6.395 23.989-5.054 25.764-7.521 5.908-2.767 4.553 4.724h4.425l-.176 5.243 7.885 1.97-5.698 9.367.763 7.94 7.808 4.433-8.511 13.719-2.315-2.313-1.201 3.336-54.747 9.029-3.023-1.512-5.681 5.838zm-4.746-1.846h3.966l6.079-6.245 3.696 1.846 53.088-8.754 1.823-5.054 2.722 2.724 6.268-10.108-6.971-3.958-.913-9.508 4.716-7.748-6.899-1.721.163-4.827h-3.301l-4.18-4.339-4.91 2.287-25.935 7.568-25.356 5.341-3.16-5.684-8.232 6.523 1.552 2.399-.506 11.149 5.728 12.699-3.82 1.04 4.395 14.378-.013-.008z"
            ></path>
            <path
                fill="#EEE"
                d="M712.967 196.36l1.008 4.253h3.361v2.682l3.023 4.698 6.384-1.006 3.246-10.065 3.022-.562-3.584-3.353 3.584-4.81v-9.846l-2.654-2.857-1.937 1.739-2.35-3.02 3.19-3.525-.798-3.859-5.753-2.514-5.373-1.345-5.209 8.558.84 8.724 7.392 4.197-7.392 11.911z"
            ></path>
            <path
                fill="#fff"
                d="M719.909 208.999l-3.495-5.43v-2.035h-3.164l-1.265-5.332 7.088-11.423-6.972-3.957-.913-9.509 5.72-9.401 6.182 1.559 6.191 2.707.991 4.784-2.984 3.298 1.264 1.628 1.874-1.688 3.52 3.791v10.51l-3.271 4.394 4.313 4.044-4.279.792-3.254 10.082-7.538 1.191-.008-.005zm-1.647-5.974l2.551 3.961 5.227-.822 3.241-10.052 1.767-.326-2.851-2.672 3.893-5.225v-9.175l-1.788-1.927-1.998 1.795-3.439-4.416 3.396-3.752-.605-2.934-5.243-2.295-4.639-1.157-4.695 7.714.763 7.94 7.808 4.433-7.697 12.404.751 3.165h3.554v3.336l.004.005z"
            ></path>
            <path
                fill="#EEE"
                d="M708.937 198.04l5.6 17.671-.451 3.915 10.415-.891-1.454-4.142-.476-3.692-2.885.394.673-3.302-3.023-4.698v-2.681h-3.361l-1.008-4.253-2.516-2.519-1.514 4.198z"
            ></path>
            <path
                fill="#fff"
                d="M713.04 220.645l.553-4.839-5.634-17.783 2.11-5.855 3.734 3.73.901 3.795h3.554v3.336l3.079 4.785-.489 2.394 2.517-.343.583 4.519 1.818 5.169-12.73 1.092h.004zm-3.13-22.584l5.566 17.565-.344 2.989 8.1-.694-1.107-3.203-.355-2.771-3.25.441.857-4.206-2.967-4.609v-2.034h-3.164l-1.115-4.707-1.303-1.302-.913 2.531h-.005z"
            ></path>
            <path
                fill="#EEE"
                d="M655.021 206.931l.952 9.007 3.58-2.574 2.243 1.901 2.015-6.488 2.8-1.341 3.696 2.236 1.676-2.938h3.919l6.495 1.259-1.792 5.371 13.326 5.144-2.015 4.364 3.25 6.934 29.335.673v-11.744l-10.415.891.451-3.915-5.6-17.672-53.916 8.892z"
            ></path>
            <path
                fill="#fff"
                d="M725.427 231.421l-30.858-.707-3.67-7.842 1.784-3.859-13.227-5.11 1.736-5.208-5.372-1.041h-3.293l-1.882 3.294-4.077-2.467-1.985.951-2.311 7.439-2.757-2.338-4.288 3.079-1.209-11.452 55.566-9.166 5.9 18.623-.343 2.989 10.29-.882v13.697h-.004zm-29.67-2.527l27.822.638v-9.795l-10.535.903.553-4.839-5.299-16.725-52.269 8.621.694 6.558 2.882-2.069 1.723 1.465 1.72-5.538 3.614-1.735 3.314 2.005 1.475-2.579 4.631.017 7.529 1.461-1.848 5.533 13.42 5.183-2.247 4.865 2.821 6.026v.005z"
            ></path>
            <path
                fill="#EEE"
                d="M632.847 168.508l-8.567 5.593-4.588 8.836-7.28-3.019-5.038 3.019-11.533-1.512-1.119-2.737-20.94 6.374 1.23 11.298 2.911 18.794 1.454 17.226 2.915 1.679h4.477l1.68-2.352 3.135 4.588 2.576-3.02 3.246 4.81h4.592l2.577-5.148 8.845 7.384 3.361.449 3.357-5.816 2.127-7.05 3.919-3.692.561-7.157 3.023 4.81 3.079-4.921 5.513-13.449-2.486-8.141 3.691-1.007-5.372-11.915.502-11.072-1.848-2.852z"
            ></path>
            <path
                fill="#fff"
                d="M617.27 241.764l-4.236-.565-8.155-6.806-2.307 4.613h-5.655l-2.843-4.21-2.572 3.015-3.07-4.493-1.188 1.662h-5.2l-3.546-2.043-1.492-17.676-2.907-18.759-1.321-12.1 22.48-6.844 1.235 3.024 10.68 1.4 5.162-3.092 6.924 2.874 4.314-8.301 9.548-6.231 2.512 3.88-.506 11.149 5.729 12.699-3.821 1.04 2.307 7.547-5.707 13.894-3.863 6.172-2.319-3.692-.369 4.728-4 3.769-2.088 6.913-3.722 6.446-.004-.013zm-3.464-2.326l2.482.33 2.997-5.191 2.169-7.182 3.838-3.615.75-9.59 3.726 5.928 2.255-3.606 5.355-13.072-2.671-8.737 3.572-.973-5.021-11.127.502-10.998-1.184-1.825-7.584 4.951-4.871 9.376-7.632-3.165-4.913 2.946-12.387-1.623-1.003-2.458-19.401 5.906 1.149 10.536 2.911 18.815 1.415 16.746 2.277 1.315h3.756l2.173-3.036 3.199 4.681 2.581-3.024 3.653 5.41h3.529l2.842-5.68 9.54 7.958-.004.004z"
            ></path>
            <path
                fill="#EEE"
                d="M573.782 185.062l-6.384-2.125-22.06 2.125-7.391 4.253-3.585-2.24 1.792 12.527-.223 13.2 1.458 12.867v7.606l2.015.895-3.25 3.465 7.727 6.044-7.165 8.947-5.488 6.822 2.354 6.039 5.595-2.908 6.607-.222 3.807-.335 2.912-3.914 4.257.334-1.23-4.026 4.03-2.013 1.119 3.465 5.599-.111.669-3.354 2.8 1.229.227-6.823 2.35-7.829 4.703 2.908 1.231-2.685 2.242-1.341 1.792-3.803-2.915-1.679-1.454-17.226-2.911-18.794-1.23-11.298z"
            ></path>
            <path
                fill="#fff"
                d="M533.093 266.786l-2.92-7.496 12.404-15.457-7.786-6.086 3.048-3.255-1.376-.613v-8.154l-1.458-12.871.223-13.187-2.062-14.421 4.798 2.998 7.083-4.073 22.453-2.163 7.131 2.373 1.294 11.885 2.912 18.815 1.415 16.747 3.228 1.863-2.272 4.827-2.234 1.34-1.548 3.375-4.566-2.826-1.972 6.561-.266 8.069-2.997-1.319-.592 2.955-7.027.142-.999-3.097-2.371 1.182 1.402 4.579-5.098-.403-2.855 3.838-4.271.377-6.397.218-6.328 3.285.004-.008zm-.81-7.174l1.783 4.582 4.871-2.531 6.791-.231 3.37-.296 2.967-3.991 3.413.269-1.063-3.477 5.694-2.844 1.239 3.837 4.172-.081.75-3.756 2.602 1.143.185-5.58 2.731-9.102 4.836 2.994.914-1.996 2.242-1.345 1.312-2.779-2.594-1.495-1.492-17.676-2.907-18.759-1.175-10.751-5.634-1.876-21.664 2.086-7.697 4.429-2.366-1.478 1.522 10.635-.223 13.213 1.453 12.909v7.007l2.654 1.177-3.447 3.679 7.666 5.997-12.905 16.087z"
            ></path>
            <path
                fill="#EEE"
                d="M545.338 185.062l5.6-7.829-1.569-18.683-7.165-7.199 4.365-15.899-4.365-8.446 10.749-7.662-.896 7.44 7.164-10.738v-7.692l8.065-1.255 6.496 2.681 8.51 2.017.892 18.567-.892 7.157-4.48 3.581 4.48 4.976 6.942-8.107 4.03-2.519 6.942 8.112 3.361 13.423-2.015 7.157-3.584-2.681-2.912 7.045-.334 10.181-20.94 6.373-6.384-2.124-22.06 2.124z"
            ></path>
            <path
                fill="#fff"
                d="M543.405 186.176l6.581-9.205-1.514-18.005-7.306-7.341 4.412-16.066-4.57-8.835 13.107-9.342-.682 5.671 4.862-7.285v-8.202l9.098-1.422 6.667 2.754 9.116 2.158.934 19.342-.952 7.611-4.008 3.204 3.121 3.469 6.35-7.418 4.827-3.015 7.602 8.878L604.523 157l-2.448 8.69-3.709-2.78-2.392 5.791-.352 10.673-21.853 6.656-6.479-2.159-23.885 2.3v.005zm24.1-4.176l6.29 2.094 20.027-6.099.321-9.688 3.43-8.3 3.46 2.591 1.582-5.628-3.245-12.973-6.286-7.341-3.237 2.021-7.533 8.802-5.84-6.489 4.952-3.957.841-6.707-.858-17.796-7.975-1.898-6.255-2.587-7.023 1.097v7.178l-9.471 14.19 1.11-9.204-8.395 5.983 4.168 8.056-4.322 15.732 7.027 7.058 1.625 19.355-4.618 6.454 20.233-1.949-.008.005z"
            ></path>
            <path
                fill="#EEE"
                d="M616.781 240.771l-2.688 6.373 7.726 9.508 4.592-.445v2.908l5.261 1.902 4.48-3.247 2.238 2.352 7.169-2.686-2.577-3.692 3.807.112 2.127 3.357 2.577-2.462v-5.817l2.8-1.34-3.023-8.502 6.268-7.272 5.6 5.593v-4.698l6.272-2.797-5.153-5.705 7.837-3.803-1.788-10.738-3.695-2.235-2.8 1.34-2.015 6.489-2.243-1.902-3.58 2.574-.952-9.007-3.361-1.679-5.878 6.044h-5.038l-2.384-7.8-5.514 13.449-3.078 4.921-3.023-4.81-.561 7.157-3.919 3.692-2.127 7.05-3.357 5.816z"
            ></path>
            <path
                fill="#fff"
                d="M631.817 262.048l-6.332-2.287v-2.54l-4.073.398-8.391-10.322 2.954-6.989 3.306-5.727 2.169-7.182 3.838-3.615.75-9.59 3.726 5.928 2.255-3.606 6.466-15.775 2.941 9.624h3.966l6.079-6.244 4.417 2.205.836 7.928 2.881-2.068 1.723 1.464 1.72-5.538 3.614-1.734 4.485 2.715 1.976 11.838-7.353 3.568 5.18 5.735-6.886 3.071v6.326l-6.474-6.467-5.27 6.111 3.122 8.781-3.023 1.451v5.633l-3.674 3.512-2.47-3.894-1.483-.042 2.208 3.16-8.854 3.315-2.105-2.205-4.224 3.058v.004zm-4.484-3.585l4.193 1.516 4.738-3.43 2.375 2.492 5.479-2.051-2.945-4.223 6.131.18 1.788 2.818 1.475-1.409v-6.005l2.572-1.233-2.924-8.224 7.272-8.429 4.725 4.72v-3.066l5.655-2.523-5.124-5.675 8.322-4.039-1.607-9.641-2.903-1.76-1.985.951-2.311 7.439-2.757-2.338-4.288 3.079-1.067-10.082-2.303-1.152-5.681 5.838h-6.114l-1.826-5.975-4.601 11.191-3.863 6.172-2.319-3.692-.369 4.728-4 3.77-2.088 6.912-3.383 5.859-2.448 5.812 7.066 8.695 5.11-.497v3.276l.005-.004z"
            ></path>
            <path
                fill="#EEE"
                d="M533.582 265.488l1.342 4.03-3.696 2.908h-3.807l2.354 6.373-8.177 1.568-2.015 3.58-4.592 4.026h19.933l1.904-5.37 19.932.783 18.698-3.465h27.436l14.668-12.193 8.849-8.613v-2.908l-4.592.445-7.726-9.508 2.688-6.373-3.361-.45-8.845-7.384-2.577 5.148h-4.592l-3.246-4.81-2.577 3.02-3.134-4.587-1.68 2.351h-4.477l-1.792 3.804-2.242 1.34-1.231 2.686-4.703-2.909-2.35 7.83-.227 6.822-2.8-1.229-.668 3.354-5.6.111-1.119-3.465-4.03 2.013 1.23 4.026-4.257-.334-2.912 3.915-3.807.334-6.607.223-5.595 2.908z"
            ></path>
            <path
                fill="#fff"
                d="M535.575 288.898h-23.036l6.315-5.538 2.148-3.816 7.525-1.443-2.431-6.596h4.81l2.933-2.309-1.389-4.158 6.483-3.371 6.791-.231 3.37-.296 2.967-3.992 3.413.27-1.064-3.477 5.694-2.844 1.239 3.837 4.172-.081.75-3.756 2.603 1.143.184-5.581 2.731-9.101 4.837 2.994.913-1.996 2.242-1.345 1.921-4.077h4.592l2.174-3.037 3.198 4.681 2.582-3.023 3.652 5.409h3.529l2.843-5.679 9.539 7.958 4.292.569-2.941 6.982 7.066 8.694 5.111-.497v4.317l-9.18 8.935-14.925 12.407h-27.684l-18.72 3.47h-.103l-19.251-.763-1.895 5.345v-.004zm-18.132-1.846h16.825l1.912-5.396 20.507.805 18.681-3.465h27.187l14.385-11.958 8.54-8.318v-1.499l-4.073.399-8.39-10.322 2.431-5.774-2.427-.321-8.155-6.806-2.307 4.613h-5.655l-2.843-4.21-2.572 3.015-3.07-4.493-1.188 1.662h-4.369l-1.663 3.529-2.234 1.341-1.548 3.375-4.566-2.827-1.972 6.562-.266 8.069-2.997-1.319-.592 2.955-7.027.141-.999-3.096-2.371 1.182 1.402 4.578-5.098-.402-2.855 3.837-4.271.377-6.397.218-4.712 2.45 1.3 3.894-4.459 3.507h-2.805l2.269 6.155-8.824 1.692-1.882 3.345-2.864 2.514.012-.009z"
            ></path>
            <path
                fill="#EEE"
                d="M602.894 279.922l32.251-5.037 9.406.561 34.601-9.958 40.422-8.613.785-4.81-2.688-.779.338-7.945 3.469-.446-2.461-6.823 6.268-1.567-.784-4.026-29.336-.673-3.249-6.934 2.015-4.364-13.326-5.144 1.792-5.371-6.495-1.259h-3.919l-1.677 2.938 1.788 10.738-7.837 3.803 5.153 5.705-6.272 2.797v4.698l-5.6-5.594-6.268 7.273 3.023 8.502-2.8 1.34v5.817l-2.577 2.462-2.127-3.358-3.807-.111 2.577 3.692-7.169 2.685-2.238-2.351-4.48 3.247-5.261-1.902-8.85 8.613-14.667 12.194z"
            ></path>
            <path
                fill="#fff"
                d="M599.73 281.348l17.214-14.305 9.235-8.99 5.347 1.931 4.738-3.43 2.375 2.492 5.479-2.051-2.945-4.223 6.131.18 1.788 2.818 1.475-1.409v-6.005l2.572-1.234-2.924-8.223 7.272-8.429 4.725 4.72v-3.067l5.655-2.522-5.124-5.675 8.323-4.039-1.733-10.382 2.11-3.688 4.63.018 7.529 1.46-1.848 5.534 13.42 5.182-2.246 4.866 2.821 6.026 29.511.676 1.093 5.615-6.131 1.534 2.508 6.942-3.842.493-.274 6.442 2.778.809-1.016 6.24-41.01 8.738-34.72 9.988-9.467-.562-35.458 5.534.009-.004zm26.908-21.171l-8.485 8.258-12.099 10.056 29.044-4.531 9.346.556 34.511-9.928 39.805-8.48.549-3.379-2.599-.758.399-9.444 3.104-.398-2.418-6.703 6.414-1.602-.476-2.437-29.168-.668-3.67-7.842 1.784-3.859-13.227-5.11 1.736-5.208-5.372-1.041h-3.293l-1.252 2.184 1.853 11.093-7.354 3.568 5.18 5.735-6.886 3.071v6.326l-6.474-6.468-5.27 6.112 3.122 8.78-3.023 1.452v5.632l-3.674 3.513-2.47-3.894-1.483-.043 2.208 3.161-8.854 3.315-2.105-2.205-4.223 3.058-5.18-1.872z"
            ></path>
            <path
                fill="#EEE"
                d="M635.145 274.884l1.007 6.601-4.926-1.564-2.242 3.131h-6.046l-1.007 5.705-5.6-2.685-2.8 6.266-6.855 7.268-5.124-3.354v8.836h-5.154v4.476l16.349-1.007 15.341-7.272 19.486-3.131.558 3.242 4.815-3.353 1.234 3.687-1.119 5.594 12.987-4.699 16.348 6.939 10.192 9.058 1.569-3.242 5.934 1.452 4.03-2.236-4.703-2.351 2.127-2.908 2.576 1.567v-3.131l-2.349-1.678 5.934-6.601 4.476.335.45-2.013 3.696.111-.785-5.033 4.481 6.823 1.007-4.471-7.28-7.273 1.342-3.242 5.938 2.347-7.614-5.928 3.807-1.229 4.476 3.804 3.585-.339-3.696-5.255 2.688-2.463 3.361 1.345-3.923-7.05-3.245 2.574-1.793-3.13-1.342 4.809-2.576-3.358 1.903-5.37 3.919-1.564 5.934 5.928 2.577 6.377 2.354-1.456-1.792-6.823-4.927-5.482-2.688-1.452.785-3.915-3.25-.226-40.423 8.613-34.6 9.958-9.407-.562z"
            ></path>
            <path
                fill="#fff"
                d="M692.889 320.126l-10.989-9.774-15.877-6.732-14.191 5.131 1.393-6.96-.763-2.287-4.995 3.477-.652-3.777-18.453 2.964-15.384 7.289-17.506 1.08v-6.378h5.149v-9.624l5.904 3.868 6.222-6.592 3.121-6.989 5.355 2.569.93-5.268h6.346l2.354-3.293 4.15 1.319-.956-6.258 10.389.617 34.51-9.928 40.551-8.639 4.425.304-.858 4.279 2.23 1.204 5.184 5.765 2.023 7.7-3.588 2.219.784 1.413-5.235-2.09-1.659 1.521 4.142 5.893-5.557.518-4.382-3.722-1.659.536 11.323 8.814-11.083-4.386-.759 1.834 7.203 7.195-1.599 7.097-3.331-5.076.3 1.915-4.043-.124-.455 2.047-4.823-.36-4.94 5.495 1.9 1.354v5.251l-3.255-1.979-.981 1.341 5.29 2.642-5.878 3.264-5.561-1.362-1.775 3.67.009.013zm-26.81-18.489l16.824 7.135 9.39 8.347 1.359-2.818 6.311 1.546 2.183-1.212-4.116-2.055 3.275-4.476 1.895 1.152v-1.015l-2.804-2 6.929-7.705 4.133.308.442-1.979 3.348.103-1.144-7.345-1.437-1.435 1.93-4.656.789.313-3.906-3.041 5.955-1.927 4.575 3.884 1.612-.149-3.246-4.622 3.713-3.401 1.484.596-2.127-3.825-3.237 2.566-1.226-2.146-1.248 4.463-4.004-5.221 2.212-6.236 4.875-1.949 6.5 6.493 2.233 5.534.837-.519-1.561-5.949-4.669-5.195-3.147-1.7.711-3.555-2.07-.142-40.265 8.579-34.72 9.992-8.425-.501 1.059 6.943-5.702-1.812-2.127 2.973h-5.745l-1.085 6.142-5.839-2.801-2.474 5.542-7.499 7.949-4.339-2.844v8.052h-5.149v2.57l15.19-.934 15.298-7.251 20.516-3.298.467 2.711 4.635-3.233 1.702 5.096-.845 4.228 11.791-4.266-.017-.004zm49.195-10.841l4.33 6.596.416-1.854-4.746-4.742zm8.819-22.061l3.576 6.424-1.933-4.788-1.638-1.636h-.005zm-8.292 1.961l1.145 1.491 1.441-5.157 2.354 4.116 3.215-2.548-3.593-3.59-2.967 1.187-1.595 4.501z"
            ></path>
            <path
                fill="#EEE"
                d="M612.747 308.558l2.465 10.065 6.607-3.02 1.231 5.482 18.14 10.065-1.569 6.155 6.607 2.908 7.169 10.96.557 6.155 5.827.557v-5.256l7.726 1.007-2.466-3.469 6.046-.784-4.142-4.471 4.704.111 1.007-3.358 3.584 1.902 3.135-4.138-4.142-.334 1.007-5.037 8.061 1.568.896-6.377-3.134-3.577 3.919-3.692 6.607-3.357-10.192-9.059-16.348-6.938-12.987 4.698 1.119-5.594-1.235-3.687-4.815 3.353-.557-3.242-19.487 3.131-15.34 7.273z"
            ></path>
            <path
                fill="#fff"
                d="M660.702 358.904l-7.597-.728-.609-6.686-6.89-10.54-7.07-3.114 1.586-6.219-17.879-9.924-1.071-4.775-6.586 3.011-2.907-11.881 16.142-7.65 20.516-3.298.468 2.712 4.634-3.234 1.703 5.097-.845 4.227 11.791-4.266 16.824 7.136 11.315 10.06-7.697 3.911-3.172 2.989 2.825 3.229-1.089 7.757-8.116-1.576-.622 3.114 4.785.389-4.626 6.108-3.284-1.743-.888 2.951-3.224-.077 3.876 4.184-6.299.814 2.8 3.94-8.781-1.143v5.221l-.013.004zm-5.895-2.42l4.047.39v-5.294l6.672.869-2.131-2.998 5.797-.749-4.412-4.763 6.182.146 1.128-3.761 3.885 2.06 1.642-2.171-3.499-.283 1.394-6.951 8.009 1.555.703-4.994-3.443-3.932 4.665-4.394 5.518-2.801-9.064-8.061-15.872-6.733-14.192 5.131 1.393-6.96-.763-2.287-4.995 3.478-.652-3.778-18.453 2.964-14.543 6.891 2.019 8.254 6.629-3.028 1.389 6.189 18.402 10.21-1.552 6.086 6.144 2.703 7.443 11.384.51 5.619v.009z"
            ></path>
            <path
                fill="#EEE"
                d="M596.398 309.564l-19.594 4.026 9.797 42.281 6.886 2.797.785 9.063-2.466 2.347 2.131 11.633 1.904 7.272 4.926 6.262 24.409-4.583 12.207-2.129 4.591 1.902.446 3.247h2.912l1.68-7.718-3.807-.561 4.481-2.013 8.06 2.235 1.008-6.934-3.135-1.79 2.354-4.921 4.142-1.902-3.807-4.698 3.473-7.496-5.827-.556-.558-6.155-7.168-10.96-6.607-2.908 1.569-6.155-18.141-10.065-1.23-5.482-6.607 3.019-2.465-10.065-16.349 1.007z"
            ></path>
            <path
                fill="#fff"
                d="M600.385 396.255l-5.393-6.857-1.968-7.525-2.217-12.125 2.504-2.386-.699-8.056-6.808-2.762-10.119-43.67 20.653-4.236 17.12-1.053 2.38 9.731 6.628-3.028 1.389 6.189 18.402 10.21-1.552 6.086 6.144 2.703 7.443 11.384.511 5.619 6.358.613-3.777 8.155 4.193 5.173-4.922 2.258-1.839 3.846 2.936 1.674-1.247 8.596-8.751-2.428-1.291.582 1.66.244-2.05 9.41h-4.463l-.485-3.516-3.854-1.598-11.941 2.078-24.949 4.689h.004zm-3.7-7.692l4.464 5.671 23.86-4.484 12.481-2.176 5.329 2.206.412 2.972h1.359l1.312-6.026-5.956-.874 7.666-3.448 7.375 2.048.767-5.277-3.335-1.902 2.864-5.996 3.366-1.546-3.422-4.223 3.164-6.836-5.286-.509-.609-6.686-6.89-10.541-7.07-3.113 1.586-6.219-17.879-9.924-1.072-4.775-6.585 3.011-2.547-10.404-15.512.955-18.604 3.825 9.476 40.894 6.967 2.827.87 10.065-2.422 2.312 2.032 11.111 1.848 7.049-.009.013z"
            ></path>
            <path
                fill="#EEE"
                d="M595.841 388.983l-43.951 3.692 3.19 3.692-.841 3.859h4.704l1.007 4.026 3.863-1.512 1.514 2.519 19.315-2.18 2.856 2.18 3.189-1.007 3.696 11.243 2.35-3.525 3.696 1.007-1.008-4.193 5.205 4.193 2.35-8.224 3.528 1.846 2.238-4.364h4.815l-2.015 5.538 10.247 1.679v4.531l6.384 6.378h7.559l-3.528 5.704 3.528 3.859v5.538l-3.863 4.193.335 6.545 7.053 1.679v6.039l11.923 6.377-5.376 5.538h8.566s3.863 6.878 4.536 7.718c.674.839 0 8.557 0 8.557l4.871-4.865 6.551 4.865-1.68 3.358 1.68 6.378h4.871l-2.521-4.194 1.175-4.364 3.357 4.866 9.407-2.686-3.529-3.859-.506-6.377 2.856-1.512-3.19-3.354 2.855-1.679-3.19-2.852 4.704-3.354-3.362-4.865 3.362-6.21-9.068-8.725-.335-4.193-3.863 1.927 2.182-5.619-4.87-2.852.506-7.551-3.696-1.512-.167-5.705-3.863-4.026-2.016-8.891-5.32-.84.784-4.026-4.87-4.137 4.086-4.42-4.369-5.2-8.061-2.236-4.48 2.013 3.807.562-1.681 7.717h-2.911l-.446-3.242-4.592-1.901-12.206 2.124-24.413 4.587-4.927-6.266.013-.009z"
            ></path>
            <path
                fill="#fff"
                d="M680.841 492.76h-7.216l-1.951-7.401 1.488-2.977-5.287-3.927-6.101 6.094.219-2.522c.283-3.264.42-7.196.154-7.962-.699-.938-3.396-5.697-4.309-7.311H647.63l6.041-6.224-11.332-6.06v-5.863l-7.019-1.671-.39-7.619 3.88-4.215v-4.818l-3.756-4.107 3.023-4.892h-6.286l-6.924-6.917v-4.128l-10.573-1.731 1.947-5.345h-2.933l-2.405 4.69-3.349-1.752-2.422 8.472-4.181-3.366.738 3.075-4.558-1.242-3.035 4.548-4.005-12.176-2.778.878-2.942-2.249-19.538 2.206-1.415-2.356-4.116 1.606-1.089-4.347h-5.128l.987-4.527-4.078-4.716 46.254-3.884 4.888 6.214 23.86-4.484 12.481-2.176 5.329 2.206.412 2.973h1.359l1.312-6.027-5.956-.873 7.666-3.448 8.661 2.403 5.072 6.043-3.987 4.309 4.549 3.867-.703 3.619 4.991.788 2.096 9.256 3.932 4.099.158 5.46 3.756 1.534-.51 7.645 5.055 2.959-1.603 4.125 2.949-1.473.416 5.208 9.326 8.968-3.426 6.326 3.554 5.148-4.502 3.213 3.276 2.925-2.945 1.73 3.228 3.397-3.396 1.794.433 5.457 4.318 4.724-11.478 3.276-2.602-3.769-.553 2.056 3.155 5.255.022.009zm-5.793-1.846h2.526l-1.883-3.135 1.797-6.669 4.116 5.962 7.331-2.094-2.735-2.994-.579-7.298 2.316-1.225-3.152-3.315 2.766-1.628-3.109-2.779 4.905-3.5-3.164-4.582 3.293-6.091-8.815-8.48-.253-3.178-4.781 2.385 2.765-7.118-4.686-2.745.498-7.457-3.636-1.486-.176-5.949-3.794-3.958-1.934-8.531-5.647-.891.862-4.437-5.192-4.412 4.189-4.527-3.662-4.36-7.464-2.073-1.291.582 1.659.245-2.049 9.409h-4.463l-.485-3.516-3.854-1.598-11.941 2.078-24.949 4.69-4.965-6.314-41.649 3.5 2.307 2.668-.695 3.191h4.279l.926 3.709 3.61-1.414 1.608 2.677 19.097-2.154 2.769 2.116 3.606-1.14 3.387 10.305 1.668-2.497 2.83.771-1.278-5.315 6.234 5.024 2.281-7.971 3.709 1.941 2.071-4.035h6.697l-2.084 5.731 9.921 1.623v4.934l5.84 5.833h8.832l-4.03 6.519 3.297 3.611v6.253l-3.846 4.176.279 5.465 7.091 1.687v6.215l12.52 6.694-4.708 4.848h6.925l.266.472c1.479 2.634 3.996 7.024 4.45 7.593.497.626.63 2.879.39 6.72l3.67-3.67 7.812 5.803-1.869 3.735 1.41 5.354.013.017z"
            ></path>
            <path
                fill="#EEE"
                d="M576.804 313.589l-37.511 2.236v37.249l-1.681 18.794 2.689 24.16 1.676 10.738 5.377 2.518-2.689-6.206 3.696-3.692.167 4.866 5.038 6.373 6.384-6.373-1.007-4.026h-4.704l.841-3.859-3.19-3.692 43.951-3.692-1.904-7.273-2.13-11.632 2.465-2.347-.785-9.063-6.885-2.797-9.798-42.282z"
            ></path>
            <path
                fill="#fff"
                d="M553.484 412.013l-5.869-7.427-.107-3.045-1.75 1.747 3.404 7.868-8.03-3.76-1.754-11.226-2.701-24.293 1.685-18.845v-38.08l39.162-2.335 9.866 42.578 6.967 2.826.871 10.065-2.423 2.313 2.032 11.11 2.174 8.301-43.239 3.632 2.306 2.668-.694 3.191h4.279l1.307 5.234-7.486 7.478zm-4.043-8.1l4.206 5.324 5.278-5.272-.707-2.823h-5.128l.986-4.527-4.077-4.715 44.663-3.752-1.642-6.279-2.217-12.125 2.504-2.386-.699-8.056-6.809-2.763-9.728-41.99-35.865 2.137v36.461l-1.677 18.7 2.676 24.07 1.599 10.215 2.723 1.272-1.968-4.548 5.642-5.637.232 6.686.008.008z"
            ></path>
            <path
                fill="#EEE"
                d="M514.991 287.973l-3.246 7.217-1.848 2.852 2.547 1.174-1.539 5.705-3.696 1.006 2.182 2.63-2.182 1.396 2.015 1.341-2.688 2.295 1.346 3.41-2.354 3.692 33.765-4.866 37.511-2.236 19.594-4.026v-4.475h5.154v-8.836l5.123 3.353 6.856-7.268 2.8-6.266 5.599 2.686 1.008-5.705h6.045l2.243-3.131 4.926 1.563-1.008-6.6-32.25 5.037h-27.436l-18.698 3.465-19.933-.784-1.903 5.371h-19.933z"
            ></path>
            <path
                fill="#fff"
                d="M503.668 321.894l3.181-4.989-1.41-3.59 2.259-1.927-2.174-1.448 2.496-1.597-2.431-2.93 4.57-1.246 1.201-4.441-2.809-1.294 2.389-3.688 3.464-7.696h19.877l1.912-5.397 20.507.806 18.681-3.465h27.449l33.095-5.166 1.372 8.986-5.702-1.812-2.127 2.973h-5.745l-1.085 6.142-5.84-2.801-2.473 5.542-7.499 7.949-4.339-2.844v8.052h-5.15v4.304l-20.464 4.198-37.473 2.235-35.715 5.149-.017-.005zm3.97-8.03l1.274 3.238-1.522 2.39 31.847-4.588 37.447-2.231 18.793-3.863v-4.647h5.149v-9.624l5.904 3.867 6.221-6.591 3.121-6.99 5.356 2.57.93-5.268h6.345l2.354-3.294 4.151 1.319-.644-4.214-31.406 4.9h-27.423l-18.719 3.469h-.103l-19.251-.763-1.895 5.346h-19.988l-3.07 6.792-1.269 1.958 2.289 1.053-1.882 6.969-2.821.771 1.934 2.33-1.874 1.199 1.856 1.238-3.117 2.659.013.005z"
            ></path>
            <path
                fill="#EEE"
                d="M505.528 320.691l-6.551 5.204 1.179 5.872-4.369 2.518 1.008 3.187h-3.19l1.676 3.019-1.676 4.193v3.358l-1.681 1.341 2.35 6.043-3.863 9.397 5.543 2.347 2.015 5.538-1.342 2.852-3.022-.839-.841 2.852 3.023 1.846-6.213 3.02v4.364l-.673 8.442 2.856 3.636 2.182-2.013 24.525-3.353-3.362 6.711 6.217 1.846 2.35 6.54 6.05-2.852 12.258 1.006-1.677-10.737-2.688-24.161 1.681-18.793v-37.249l-33.765 4.865z"
            ></path>
            <path
                fill="#fff"
                d="M523.146 409.884l-2.538-7.062-6.822-2.026 3.07-6.129-22.501 3.079-2.705 2.497-3.696-4.707.699-8.767v-4.904l5.218-2.536-2.187-1.336 1.295-4.403 3.147.874.849-1.799-1.745-4.797-6.015-2.548 4.069-9.898-2.466-6.33 1.865-1.491v-3.088l1.582-3.953-2.229-4.009h3.503l-.853-2.694 4.437-2.557-1.158-5.778 7.191-5.709.248-.034 34.819-5.02v38.397l-1.676 18.7 2.675 24.07 1.857 11.855-13.184-1.083-6.745 3.182-.004.004zm-6.723-10.232l5.608 1.666 2.166 6.026 5.35-2.522 11.337.929-1.501-9.581-2.701-24.293 1.685-18.845v-36.144l-32.457 4.677-5.912 4.694 1.196 5.97-4.3 2.48 1.162 3.684h-2.882l1.132 2.034-1.775 4.437v3.624l-1.496 1.195 2.238 5.752-3.657 8.891 5.072 2.15 2.285 6.275-1.839 3.906-2.899-.805-.381 1.302 3.858 2.355-7.215 3.508v3.859l-.648 8.086 2.015 2.566 1.66-1.534 26.548-3.632-3.649 7.294v-.004z"
            ></path>
            <path
                fill="#EEE"
                d="M449.092 359.619l-1.008 17.62 7.392 4.866 2.015 10.57-3.022 15.937-2.856 9.063 1.175 5.872 12.935-1.174v2.514l4.699 2.853 2.689-2.013 4.703 2.013.841-9.226 4.368-1.006 5.038 5.366 9.909 1.178 2.855 8.386 3.529-6.544 5.544 3.024-1.008 4.36 5.711-3.02-9.072-9.731 2.521-.839 4.536 5.032 3.691-2.514 2.856 5.705 5.711.668 4.369 6.044 4.703-6.378-3.19-2.685-8.232.167-2.015-5.538-12.262-5.872-4.369-4.866 3.696-.839 14.95 7.551-4.87-6.373 6.045-1.178-2.349-6.54-6.217-1.846 3.361-6.711-24.524 3.353-2.183 2.013-2.855-3.636.673-8.442v-4.364l6.212-3.02-3.022-1.846.84-2.852 3.023.839 1.342-2.852-2.015-5.538-5.544-2.347 2.139-5.204h-43.458z"
            ></path>
            <path
                fill="#fff"
                d="M529.204 436.186l-4.871-6.737-5.801-.681-2.606-5.204-3.49 2.377-4.669-5.182-.584.193 8.914 9.564-8.562 4.531 1.303-5.649-4.111-2.24-4.095 7.593-3.348-9.846-9.665-1.144-4.904-5.229-3.199.737-.896 9.829-5.381-2.304-2.761 2.068-5.681-3.447v-2.026l-12.751 1.156-1.385-6.917 2.911-9.234 2.98-15.714-1.908-10.014-7.507-4.942 1.089-19.021h45.709l-2.315 5.632 5.072 2.15 2.285 6.275-1.839 3.906-2.899-.805-.381 1.302 3.859 2.355-7.212 3.508v3.859l-.647 8.086 2.015 2.566 1.659-1.534 26.548-3.632-3.648 7.294 5.608 1.666 2.872 7.997-5.642 1.096 6.414 8.395-18.248-9.217-1.839.415 3.194 3.559 12.434 5.958 1.917 5.272 7.919-.162 4.12 3.465-5.968 8.09-.009-.013zm-9.467-9.135l5.617.659 3.863 5.345 3.439-4.659-2.264-1.902-8.545.176-2.114-5.804-12.087-5.791-5.539-6.171 5.552-1.26 11.65 5.885-3.328-4.355 6.453-1.255-1.831-5.093-6.821-2.026 3.07-6.129-22.501 3.08-2.706 2.497-3.695-4.707.698-8.767v-4.904l5.218-2.536-2.186-1.336 1.295-4.403 3.147.874.849-1.799-1.745-4.797-6.016-2.549 1.964-4.771h-41.208l-.926 16.215 7.272 4.789 2.122 11.127-3.082 16.22-2.783 8.835.965 4.827 13.115-1.19v3.006l3.726 2.258 2.615-1.958 4.026 1.722.785-8.63 5.54-1.277 5.17 5.513 10.153 1.203 2.358 6.934 2.963-5.495 6.976 3.799-.712 3.075 2.86-1.511-9.227-9.903 4.459-1.486 4.399 4.883 3.902-2.66 3.104 6.206-.009-.004z"
            ></path>
            <path
                fill="#EEE"
                d="M449.092 359.619l1.175-10.737h-10.916V310.96l-2.35-16.947h22.171l27.714-2.853h14.106l2.856 4.03-5.038 4.361 12.095 5.37-3.696 1.007 2.182 2.63-2.182 1.396 2.015 1.34-2.688 2.296 1.346 3.409-2.354 3.692-6.551 5.204 1.179 5.872-4.369 2.518 1.008 3.187h-3.19l1.676 3.02-1.676 4.193v3.357l-1.681 1.341 2.35 6.043-1.724 4.193h-43.458z"
            ></path>
            <path
                fill="#fff"
                d="M493.172 360.544h-45.109l1.175-10.738h-10.813v-38.778l-2.487-17.933h23.187l27.762-2.852h14.586l3.614 5.101-4.575 3.957 13.137 5.834-4.815 1.31 1.934 2.33-1.874 1.2 1.857 1.237-3.117 2.66 1.273 3.238-2.688 4.219-6.23 4.947 1.196 5.97-4.3 2.48 1.162 3.683h-2.881l1.132 2.035-1.775 4.437v3.623l-1.497 1.195 2.238 5.752-2.101 5.11.009-.017zm-43.047-1.846h41.808l1.351-3.281-2.466-6.33 1.861-1.491v-3.088l1.582-3.953-2.229-4.009h3.503l-.854-2.694 4.438-2.557-1.158-5.777 6.873-5.457 2.015-3.165-1.41-3.589 2.259-1.928-2.174-1.447 2.496-1.598-2.431-2.929 2.577-.703-11.054-4.908 5.506-4.767-2.097-2.955h-13.583l-27.762 2.852h-21.111l2.212 16.023v36.996H451.3l-1.175 10.738v.017z"
            ></path>
            <path
                fill="#EEE"
                d="M242.661 374.888l30.57 29.531 3.022 14.43 19.487 13.088 8.399-14.095 8.061-3.692 18.814 5.371 16.459 28.525 14.445 13.423v10.403l3.357 5.367v7.049l14.11 7.551 6.05-1.846 6.045 5.033 6.385-4.699-8.065-15.436 3.022-14.767 5.043-2.013v-5.705l4.364 2.013v-6.712l7.726 4.031v-8.729l8.065 3.358 18.981-16.108-7.559-4.699 7.559-1.006V421.2l5.205 1.007-2.349 4.698 4.03 2.681 1.676-4.36 7.225-1.679-1.175-5.872 2.855-9.063 3.023-15.937-2.015-10.57-7.392-4.865 1.008-17.621 1.175-10.737h-10.916l-10.248-6.879-10.748 2.682-11.088.672-13.771.334-14.779-3.688-6.05-7.049-6.38 3.358-6.05-5.371-10.161 2.351v-19.466l-2.268-24.666h-41.992l-1.677 84.401-61.478-.673z"
            ></path>
            <path
                fill="#fff"
                d="M391.446 496.199l-6.221-5.178-5.938 1.812-14.933-7.992v-7.337l-3.362-5.371v-10.266l-14.252-13.243-.068-.12-16.276-28.199-18.123-5.174-7.49 3.431-8.751 14.686-20.606-13.843-3.044-14.519-32.036-30.945 62.88.686 1.681-84.388h43.741l2.35 25.587v18.301l9.497-2.197 5.925 5.263 6.47-3.4 6.333 7.384 14.372 3.589 13.647-.334 10.967-.664 11.066-2.763 10.355 6.952h11.666l-1.286 11.739-.978 17.064 7.272 4.788 2.122 11.127-3.083 16.22-2.782 8.836 1.303 6.518-7.619 1.769-1.916 4.981-5.673-3.777 2.174-4.339-2.911-.561v3.045l-5.81.775 6.449 4.009-20.379 17.295-7.306-3.041v8.862l-7.726-4.026v6.63l-4.369-2.013v4.886l-5.158 2.061-2.86 13.958 8.275 15.847-7.601 5.598.012-.009zm-5.805-7.234l5.874 4.891 5.162-3.799-7.846-15.024 3.19-15.573 4.922-1.966v-6.523l4.369 2.013v-6.793l7.726 4.026v-8.587l8.82 3.67 17.583-14.922-8.67-5.388 9.309-1.237v-3.667l7.503 1.452-2.53 5.058 2.393 1.594 1.44-3.744 6.826-1.584-1.046-5.23 2.911-9.234 2.98-15.714-1.908-10.014-7.508-4.942 1.042-18.194 1.064-9.714h-10.17l-10.14-6.806-10.522 2.613-11.088.672-13.93.338-15.186-3.794-5.758-6.712-6.299 3.311-6.169-5.478-10.831 2.501v-20.584l-2.186-23.783h-40.243l-1.681 84.409-60.072-.655 29.104 28.113 3.005 14.34 18.364 12.335 8.047-13.505 8.635-3.953 19.5 5.564.189.325 16.391 28.405 14.637 13.603v10.54l3.362 5.371v6.759l13.283 7.109 6.156-1.88-.004-.013z"
            ></path>
            <path
                fill="#EEE"
                d="M305.816 291.16v-11.239h17.472l2.015 3.688 57.444 1.931h54.254v8.472l2.35 16.948v37.921l-10.247-6.878-10.749 2.681-11.088.673-13.771.334-14.779-3.688-6.05-7.05-6.38 3.358-6.05-5.371-10.161 2.352v-19.467l-2.268-24.665h-41.992z"
            ></path>
            <path
                fill="#fff"
                d="M440.273 350.612l-11.345-7.619-10.522 2.612-11.087.673-13.93.338-15.187-3.795-5.758-6.711-6.298 3.311-6.17-5.478-10.83 2.501V315.86l-2.187-23.783h-42.073v-13.089h18.942l2.024 3.709 56.917 1.91h55.146v9.333l2.349 17.012v39.652l.009.008zm-10.998-9.607l9.146 6.142v-36.122l-2.35-17.012v-7.551h-53.328l-58.002-1.949-2.006-3.675h-15.997v9.397h41.91l2.35 25.587v18.301l9.497-2.197 5.925 5.263 6.47-3.4 6.333 7.384 14.372 3.589 13.647-.334 10.967-.664 11.066-2.763v.004z"
            ></path>
            <path
                fill="#EEE"
                d="M437.001 285.541l-2.521-36.157 1.261-8.613h-4.957l-2.349-5.816 2.349-6.044h-9.741l-4.03-14.095h59.793v9.397l8.065 10.742 8.734 9.731 8.06-3.915v7.538l-5.037 1.872 5.037 12.96 9.24 2.347 10.693 14.879-2.015 3.581-4.592 4.026-5.093 10.069 2.546 1.173-1.539 5.705-12.095-5.37 5.038-4.361-2.856-4.03h-14.106l-27.714 2.853h-22.171v-8.472z"
            ></path>
            <path
                fill="#fff"
                d="M511.514 306.202l-14.41-6.399 5.505-4.767-2.097-2.955h-13.583l-27.761 2.852h-23.097v-9.363l-2.526-36.225 1.119-7.654h-4.51l-2.723-6.733 1.994-5.122h-9.09l-4.557-15.941h61.946v10.013l7.855 10.459 8.24 9.183 8.764-4.257v9.658l-4.763 1.769 4.527 11.645 9.103 2.317 11.233 15.629-2.384 4.232-4.575 4.013-4.583 9.054 2.388 1.101-2.024 7.499.009-.008zm-11.002-6.905l9.78 4.343 1.055-3.91-2.71-1.251 5.608-11.08 4.609-4.039 1.651-2.929-10.153-14.125-9.373-2.386-5.552-14.279 5.317-1.975v-5.422l-7.362 3.576-9.253-10.313-8.244-10.986v-8.78h-57.646l3.503 12.249h10.397l-2.71 6.956 1.981 4.899h5.402l-1.402 9.573 2.517 36.122v7.551h21.202l27.762-2.852h14.586l3.614 5.101-4.575 3.962-.004-.005z"
            ></path>
            <path
                fill="#EEE"
                d="M476.807 214.816l4.703 2.685v-4.36h4.369l1.676-9.645h-4.03l-1.342-6.801 11.757-1.675 3.022-9.958-8.06-7.829 16.627-4.026h24.357l4.476 13.868 1.793 12.528-.223 13.2 1.457 12.866v7.607l2.015.895-3.249 3.465 7.726 6.043-7.165 8.947-5.488 6.823 2.354 6.039 1.342 4.03-3.696 2.908h-3.807l2.354 6.373-8.177 1.568-10.693-14.879-9.239-2.347-5.038-12.96 5.038-1.872v-7.538l-8.061 3.914-8.734-9.73-8.064-10.742v-9.397z"
            ></path>
            <path
                fill="#fff"
                d="M521.191 281.386l-10.843-15.085-9.373-2.386-5.552-14.279 5.316-1.975v-5.422l-7.361 3.577-9.253-10.314-8.245-10.986v-11.294l4.704 2.685v-3.696h4.515l1.359-7.799h-3.692l-1.681-8.498 12.138-1.73 2.689-8.853-8.858-8.604 18.363-4.446h25.138l4.72 14.665 1.801 12.6-.223 13.213 1.454 12.909v7.007l2.654 1.178-3.448 3.679 7.667 5.996-12.906 16.087 2.174 5.585 1.548 4.643-4.459 3.508h-2.804l2.268 6.154-9.823 1.881h.013zm-18.835-19.021l9.102 2.317 10.547 14.669 6.526-1.25-2.431-6.596h4.811l2.932-2.309-1.132-3.388-2.538-6.514 12.404-15.457-7.786-6.086 3.048-3.255-1.376-.613v-8.155l-1.458-12.87.223-13.187-1.771-12.378-4.248-13.157h-23.573l-14.895 3.606 7.267 7.058-3.357 11.055-11.375 1.623 1.008 5.101h4.369l-2.002 11.491h-4.219v5.033l-4.704-2.686v7.495l7.855 10.459 8.241 9.183 8.763-4.257v9.658l-4.763 1.769 4.528 11.645.004-.004z"
            ></path>
            <path
                fill="#EEE"
                d="M488.901 177.232l-7.057-4.026-3.023-21.646-8.06-7.213-6.719-7.049-4.369 2.013-4.364-3.692 2.182-14.091h-4.198l10.411-12.083-3.358-10.403 2.35-4.026 4.369 2.685 14.779-7.05v7.05l5.377.669 1.68 5.705 14.111.338 2.516 2.685h9.913l1.676 4.026h4.481v6.712l4.926-2.352v8.057l-6.384 3.692-1.007 6.711 4.703 2.347 2.688-9.058 4.704-2.013 2.015 3.353-2.015 7.718-1.342 9.787-2.689 14.039 4.031 5.371-1.342 7.718h-24.358l-16.627 4.026z"
            ></path>
            <path
                fill="#fff"
                d="M488.76 178.217l-7.769-4.432-3.04-21.758-7.863-7.041-6.264-6.57-4.301 1.983-5.213-4.407 2.096-13.543h-5.136l11.392-13.213-3.327-10.3 3.035-5.2 4.747 2.916 15.649-7.46v7.696l5.167.647 1.659 5.64 13.823.33 2.512 2.677h10.127l1.681 4.026h4.789v6.172l4.927-2.347v10.048l-6.457 3.734-.841 5.611 3.117 1.555 2.496-8.421 5.844-2.501 2.632 4.382-2.101 8.039-1.346 9.782-2.611 13.637 4.026 5.362-1.544 8.875h-25.022l-16.88 4.086-.004-.005zm-6.062-5.589l6.341 3.619 16.378-3.966h23.689l1.14-6.561-4.039-5.375 2.761-14.417 1.364-9.872 1.916-7.346-1.402-2.329-3.563 1.524-2.876 9.701-6.29-3.143 1.175-7.813 6.306-3.649v-6.056l-4.926 2.347v-7.251H516.5l-1.68-4.026h-9.694l-2.53-2.694-14.398-.343-1.697-5.769-5.583-.698v-6.399l-13.913 6.635-3.991-2.454-1.668 2.856 3.391 10.502-9.437 10.948h3.263l-2.268 14.647 3.52 2.977 4.433-2.043 7.152 7.499 8.288 7.419 3.005 21.53h.005z"
            ></path>
            <path
                fill="#EEE"
                d="M487.221 98.37l18.307-12.416h5.377l-.562 4.698 5.098-2.347 9.403 6.712 12.768.672 6.268-5.037h7.842v-4.698h5.038v6.21l8.061-1.512 1.903 6.044 6.161 1.674-4.703 4.031-7.057-3.692.673 5.705h-5.038v-5.033l-4.369-.338v5.371h-8.511l-4.587 6.711-6.05-4.026-6.719 8.386-4.926 2.351v-6.711h-4.48l-1.677-4.026h-9.913l-2.516-2.685-14.111-.339-1.68-5.705z"
            ></path>
            <path
                fill="#fff"
                d="M520.672 119.301v-7.251H516.5l-1.68-4.026h-9.694l-2.53-2.694-14.397-.343-2.063-6.998 19.101-12.956h6.702l-.489 4.09 4.081-1.88 9.626 6.866 12.142.638 6.251-5.016h7.242v-4.698h6.886v6.018l7.773-1.457 1.998 6.34 7.353 2.004-6.504 5.568-6.058-3.17.587 4.998h-7.001v-5.101l-2.521-.192v5.293h-8.949l-4.832 7.063-6.101-4.064-6.319 7.893-6.44 3.071.008.004zm-2.937-9.097h4.789v6.172l3.413-1.628 7.118-8.887 5.989 3.992 4.352-6.36h8.073v-5.444l6.217.475v4.964h3.075l-.755-6.411 8.052 4.214 2.903-2.488-4.965-1.354-1.81-5.739-8.352 1.564v-6.4h-3.19v4.7h-8.438l-6.294 5.049-13.385-.703-9.188-6.557-6.11 2.818.63-5.306h-4.052l-17.514 11.88 1.299 4.412 13.823.33 2.512 2.677h10.128l1.68 4.026v.004z"
            ></path>
            <path
                fill="#EEE"
                d="M323.288 279.921l1.676-53.135 96.079 2.125h9.741l-2.349 6.043 2.349 5.816h4.957l-1.261 8.614 2.521 36.156h-54.254l-57.444-1.931-2.015-3.688z"
            ></path>
            <path
                fill="#fff"
                d="M437.991 286.466h-55.244l-58.002-1.949-2.388-4.373 1.715-54.3 96.992 2.146h11.071l-2.71 6.956 1.981 4.899h5.402l-1.402 9.573 2.581 37.048h.004zM325.86 282.71l56.917 1.91h53.229l-2.457-35.271 1.12-7.653h-4.511l-2.723-6.733 1.994-5.123h-8.391l-95.182-2.107-1.643 51.97 1.643 3.007h.004z"
            ></path>
            <path
                fill="#EEE"
                d="M417.013 214.816l-3.027-16.104-7.388-21.48-2.353-17.448h75.726l1.873 13.422 7.058 4.026 8.06 7.83-3.022 9.958-11.757 1.674 1.342 6.802h4.03l-1.676 9.645h-4.369v4.36l-4.703-2.685h-59.794z"
            ></path>
            <path
                fill="#fff"
                d="M482.436 219.095l-5.874-3.354h-60.317l-3.151-16.793-7.4-21.501-2.504-18.588h77.587l1.925 13.77 6.761 3.859 8.55 8.304-3.357 11.055-11.375 1.623 1.007 5.101h4.369l-2.002 11.491h-4.219v5.033zm-64.656-5.2h59.275l3.533 2.018v-3.697h4.515l1.359-7.799h-3.691l-1.681-8.497 12.138-1.731 2.688-8.853-7.576-7.358-7.349-4.193-1.826-13.076h-73.866l2.2 16.314 7.383 21.454.013.064 2.881 15.354h.004z"
            ></path>
            <path
                fill="#EEE"
                d="M404.245 159.784l1.68-33.896h-3.696l-1.68-4.026 5.376-6.039-5.376-15.436-2.35-28.863v-15.77h23.179v-9.73h5.711v14.09l12.262 1.345 3.863 3.354 2.015-4.699 8.065-.334 4.197 6.712 2.856-2.347 5.038 2.013 3.918 4.36 4.648.338 2.856-3.358 20.155 1.68-13.771 4.025-20.495 21.813-2.349 4.026 3.357 10.404-10.41 12.082h4.197l-2.182 14.091 4.365 3.692 4.369-2.013 6.718 7.05 8.061 7.212 1.149 8.224h-75.726z"
            ></path>
            <path
                fill="#fff"
                d="M481.034 160.705h-77.758l1.68-33.892h-3.34l-2.139-5.131 5.402-6.069-5.235-15.037-2.362-29.052V54.83h23.178V45.1h7.559v14.189l11.726 1.285 3.126 2.715 1.745-4.069 9.18-.38 3.927 6.274 2.465-2.026 5.75 2.295 3.816 4.245 3.807.274 2.86-3.362L502 68.668l-18.299 5.349-20.25 21.552-2.092 3.58 3.391 10.502-9.437 10.948h3.263l-2.268 14.647 3.52 2.977 4.433-2.043 7.152 7.5 8.288 7.418 1.342 9.607h-.009zm-75.816-1.846h73.694l-.956-6.836-7.864-7.041-6.264-6.57-4.3 1.983-5.214-4.408 2.097-13.542h-5.137l11.392-13.213-3.327-10.3 2.611-4.472 20.735-22.07 9.248-2.703-14.732-1.225-2.851 3.35-5.488-.395-4.026-4.48-4.331-1.73-3.245 2.668-4.472-7.148-6.942.287-2.285 5.328-4.6-3.996-12.799-1.4V46.948h-3.863v9.73h-23.178V71.49l2.341 28.705 5.514 15.838-5.351 6.013 1.218 2.926h4.051l-1.68 33.891.004-.004z"
            ></path>
            <path
                fill="#EEE"
                d="M324.964 226.787l3.696-23.291-32.251 1.144 4.035-40.158 20.155 4.026h56.098l8.065 4.699 6.719-3.02 8.733 3.02 6.384 4.026 7.388 21.479 3.027 16.104 4.03 14.095-96.079-2.124z"
            ></path>
            <path
                fill="#fff"
                d="M422.273 229.861l-98.39-2.175 3.688-23.227-32.187 1.139 4.241-42.221 21.064 4.21h56.261l7.868 4.583 6.62-2.977 9.183 3.174 6.749 4.257 7.529 21.916 3.014 16.065 4.365 15.256h-.005zm-96.233-3.974l93.764 2.073-3.709-12.973-3.01-16.04-7.263-21.107-6.019-3.799-8.288-2.865-6.817 3.067-8.258-4.814h-55.944l-19.246-3.847-3.825 38.093 32.311-1.143-3.705 23.355h.009z"
            ></path>
            <path
                fill="#EEE"
                d="M242.661 374.888h-27.209l-11.087 5.032 7.057-46.307 3.692-49.666.338-12.416 12.091 3.354 36.954 2.685 41.319 2.351v11.239l-1.676 84.4-61.479-.672z"
            ></path>
            <path
                fill="#fff"
                d="M203.194 381.466l7.306-47.96 3.692-49.61.369-13.577 13.145 3.649 36.86 2.677 42.176 2.398v12.112l-1.698 85.33-62.387-.681H215.65l-12.456 5.658v.004zm13.15-108.732l-.304 11.282-3.705 49.734-6.8 44.624 9.716-4.411h27.419l60.561.659 1.663-83.484v-10.352l-40.448-2.3-37.057-2.694-.09-.025-10.955-3.041v.008z"
            ></path>
            <path
                fill="#EEE"
                d="M215.452 271.531l-2.688-3.358 2.688-17.115 10.749-54.364 21.163 6.802 38.635 1.143h10.41l32.251-1.143-3.696 23.291-1.677 53.134h-17.471l-78.273-5.037-12.091-3.353z"
            ></path>
            <path
                fill="#fff"
                d="M324.179 280.842h-18.423l-78.368-5.041-12.472-3.461-3.13-3.906 2.753-17.517 10.959-55.417 22.029 7.075 38.502 1.139h10.388l33.34-1.182-3.863 24.34-1.706 53.97h-.009zm-18.333-1.846h16.541l1.655-52.295.009-.056 3.52-22.185-31.128 1.105h-10.448l-38.79-1.148-20.301-6.523-10.547 53.344-2.62 16.678 2.247 2.806 11.713 3.25 78.149 5.024z"
            ></path>
            <path
                fill="#EEE"
                d="M300.444 164.483l2.349-27.519 3.023-27.184h31.578l42.751 4.031 25.78 2.013-5.376 6.039 1.681 4.026h3.695l-1.68 33.895 2.354 17.449-6.385-4.026-8.733-3.02-6.719 3.02-8.065-4.698h-56.098l-20.155-4.026z"
            ></path>
            <path
                fill="#fff"
                d="M407.777 179.07l-7.966-5.029-8.288-2.865-6.817 3.067-8.258-4.814h-55.943l-21.056-4.206 2.422-28.341 3.117-28.028h32.491l42.747 4.031 27.624 2.158-6.225 6.994 1.217 2.926h4.052l-1.724 34.777 2.603 19.325.004.005zm-16.34-9.877l9.184 3.174 4.798 3.028-2.101-15.573 1.638-33.009h-3.34l-2.139-5.131 4.523-5.084-23.929-1.867-42.721-4.026h-30.707l-2.932 26.361-2.277 26.67 19.255 3.847h56.261l7.868 4.582 6.619-2.976v.004z"
            ></path>
            <path
                fill="#EEE"
                d="M305.816 109.78l3.023-61.41 37.627 4.365 37.623 2.681 14.11.339v15.77l2.35 28.863 5.376 15.436-68.532-6.044h-31.577z"
            ></path>
            <path
                fill="#fff"
                d="M407.267 116.868l-69.912-6.163h-32.508l3.121-63.362 38.605 4.475 37.563 2.681 14.989.356V71.49l2.341 28.705 5.805 16.673h-.004zm-100.478-8.009h30.69l67.108 5.919-4.943-14.198-2.363-29.052v-14.87l-13.252-.317-37.623-2.685-36.684-4.253-2.929 59.456h-.004z"
            ></path>
            <path
                fill="#EEE"
                d="M308.839 48.37l-66.178-6.712-26.201-2.013-53.748-11.743v10.232l-7.058 10.236 5.038 5.37-1.676 10.738 5.711 2.686 1.676 5.705 9.073 4.36-6.046 6.377 2.015 5.705-8.399 4.026.673 7.05 13.098-4.365-3.357 6.879 2.016 6.878-1.008 6.044h6.718l-2.015 13.423 9.742-3.02 4.03 3.02 4.03-5.033 4.035 1.341.334-5.705 5.711 9.397 1.681-13.423 19.817 6.373 31.577-.334 43.523 7.384 2.165-19.467 3.023-61.409z"
            ></path>
            <path
                fill="#fff"
                d="M207.64 131.991l-5.548-9.131-.231 3.953-4.567-1.52-4.188 5.229-4.369-3.272-10.698 3.315 2.075-13.817h-6.735l1.153-6.908-2.058-7.02 2.551-5.225-12.116 4.034-.845-8.844 8.232-3.949-1.929-5.456 5.565-5.868-8.301-3.992-1.676-5.7-5.96-2.802 1.715-10.977-5.239-5.585 7.314-10.613V26.75l54.808 11.975 26.141 2.009 67.074 6.801-3.07 62.343-2.273 20.434-44.406-7.533-31.646.338-18.899-6.082-1.869 14.948-.005.008zm52.561-11.054l.081.013 42.558 7.217 2.054-18.464 2.98-60.506-65.308-6.621-26.244-2.018-.064-.013-52.629-11.5v9.376l-6.796 9.86 4.836 5.156-1.642 10.502 5.463 2.566 1.68 5.709 9.84 4.733-6.53 6.882 2.105 5.954-8.566 4.107.502 5.25 14.088-4.689-4.167 8.536 1.972 6.737-.862 5.174h6.702l-1.955 13.029 8.785-2.724 3.691 2.767 3.876-4.84 3.499 1.165.437-7.461 5.87 9.662 1.488-11.898 20.738 6.673 31.509-.334h.009z"
            ></path>
            <path
                fill="#EEE"
                d="M207.053 129.246v11.744l-9.073 32.55V192l28.221 4.694 21.163 6.802 38.635 1.143h10.41l4.035-40.157 3.207-35.236-43.523-7.384-31.578.334-19.816-6.373-1.681 13.423z"
            ></path>
            <path
                fill="#fff"
                d="M297.245 205.561h-11.25l-38.79-1.148-.124-.039-21.099-6.78-28.923-4.814v-19.363l.034-.12 9.038-32.431.009-11.735 1.817-14.528 20.739 6.673 31.509-.334.082.013 44.367 7.525-3.289 36.084-4.116 40.997h-.004zm-49.722-2.986l38.502 1.14h9.548l3.949-39.327 3.13-34.375-42.597-7.225-31.646.338-18.899-6.082-1.535 12.258v11.813l-.035.119-9.038 32.431v17.548l27.513 4.582.065.022 21.038 6.758h.005z"
            ></path>
            <path
                fill="#EEE"
                d="M162.711 27.902l-15.456-2.351-15.114 56.544h4.031l3.695 7.889-3.361 7.38-1.342 5.537-8.734 3.191-1.68 9.731 5.711 2.686-4.031 7.045-8.738 36.243 20.829 2.685 24.19 1.341 26.875 6.039 8.394 1.679 9.073-32.551v-11.744l-5.711-9.397-.335 5.705-4.034-1.34-4.03 5.032-4.031-3.019-9.741 3.019 2.015-13.423h-6.718l1.007-6.043-2.015-6.879 3.357-6.878-13.098 4.364-.673-7.05 8.399-4.026-2.015-5.704 6.045-6.378-9.072-4.36-1.677-5.705-5.711-2.685 1.677-10.738-5.038-5.37 7.057-10.237V27.902z"
            ></path>
            <path
                fill="#fff"
                d="M198.641 174.616l-9.24-1.846-26.823-6.026-24.177-1.345-21.849-2.818 9.004-37.369 3.601-6.292-5.441-2.557 1.891-10.952 8.772-3.203 1.282-5.221 3.182-6.994-3.268-6.969h-4.647l15.641-58.505 17.055 2.591v11.316l-6.795 9.86 4.836 5.156-1.642 10.502 5.462 2.565 1.681 5.71 9.84 4.732-6.53 6.883 2.105 5.953-8.566 4.107.501 5.251 14.089-4.69-4.167 8.537 1.972 6.737-.862 5.174h6.701l-1.955 13.028 8.785-2.724 3.692 2.767 3.876-4.84 3.499 1.165.437-7.461 7.383 12.147v12.129l-.034.12-9.3 33.378.009.004zm-60.034-11.054l24.229 1.344.072.017 26.875 6.039 7.533 1.504 8.806-31.604v-11.363l-4.039-6.643-.231 3.953-4.566-1.52-4.189 5.229-4.369-3.272-10.698 3.315 2.076-13.817h-6.736l1.153-6.908-2.058-7.02 2.551-5.225-12.116 4.034-.845-8.844 8.232-3.949-1.929-5.456 5.565-5.868-8.301-3.992-1.676-5.7-5.96-2.802 1.715-10.977-5.239-5.585 7.315-10.613v-9.153l-13.849-2.107-14.595 54.586h3.417l4.125 8.802-3.499 7.692-1.44 5.932-8.695 3.174-1.471 8.51 5.981 2.81-4.463 7.799L118.824 161l19.774 2.549.009.013z"
            ></path>
            <path
                fill="#EEE"
                d="M147.255 25.55L78.389 7.769l-3.023-6.712h-5.711l2.015 8.052h-3.696v6.378L62.602 13.3l-9.072-7.55-8.395 13.088L46.81 39.98l-3.696 13.423 8.4 3.024v4.36l5.71-3.692 2.689 8.724-3.023 9.063 19.821-2.351 14.78 6.043 16.798-3.358 9.402 2.013 14.449 4.866 15.114-56.544z"
            ></path>
            <path
                fill="#fff"
                d="M132.776 83.285l-15.328-5.165-9.167-1.962-16.884 3.376-14.818-6.057-21.03 2.493 3.391-10.16-2.242-7.276-6.11 3.949v-5.41l-8.592-3.087 3.88-14.091-1.689-21.291 9.132-14.228 9.763 8.129 3.97 1.61V8.188h3.435L68.472.136h7.49l3.087 6.852 69.338 17.907-15.611 58.395v-.005zm-24.482-9.011l9.694 2.082 13.51 4.552 14.621-54.693-68.39-17.668-2.96-6.57h-3.93l2.014 8.052H68.9v6.823l-6.778-2.75-8.378-6.972-7.666 11.945 1.668 20.995-3.512 12.755 8.206 2.95v3.316l5.313-3.43 3.134 10.176-2.658 7.962 18.612-2.206 14.745 6.026 16.712-3.336-.004-.009z"
            ></path>
            <path
                fill="#EEE"
                d="M43.115 53.403l2.02 6.377-12.768 22.315-3.696 11.243-3.696 6.21-3.023 10.232-6.38 2.017-1.68 17.449 5.037 4.698 33.593 5.367 23.517 8.724 41.653 13.762 8.738-36.243 4.031-7.045-5.711-2.686 1.68-9.731 8.734-3.191 1.342-5.537 3.362-7.38-3.696-7.89h-4.031l-14.449-4.865-9.402-2.013-16.799 3.358-14.779-6.043-19.82 2.351 3.022-9.063-2.688-8.724-5.711 3.692v-4.36l-8.4-3.024z"
            ></path>
            <path
                fill="#fff"
                d="M118.357 162.988l-42.61-14.074-23.46-8.703-33.782-5.401-5.578-5.199L14.71 111.1l6.496-2.052 2.916-9.877 3.704-6.219 3.691-11.234 12.61-22.044-2.457-7.77 10.766 3.872v3.315l5.313-3.43 3.134 10.176-2.659 7.962 18.613-2.206L91.58 77.62l16.713-3.336 9.694 2.082 14.303 4.818h4.468l4.124 8.801-3.498 7.693-1.441 5.932-8.695 3.173-1.47 8.511 5.981 2.809-4.464 7.799-8.939 37.091v-.004zm-99.003-29.913l33.404 5.341 23.603 8.759 40.671 13.431 8.528-35.394 3.601-6.292-5.44-2.557 1.89-10.951 8.773-3.204 1.282-5.22 3.181-6.995-3.267-6.968h-3.593l-14.539-4.896-9.167-1.962-16.884 3.376-14.818-6.057-21.03 2.493 3.391-10.16-2.242-7.276-6.11 3.949v-5.41l-6.032-2.167 1.573 4.982L33.211 82.48l-3.696 11.251-3.687 6.198-3.13 10.596-6.268 1.978-1.578 16.391 4.502 4.193v-.013z"
            ></path>
            <path
                fill="#EEE"
                d="M155.654 165.433l2.015 8.108-12.091 51.344-8.399 37.921 75.585 5.367 2.688-17.115 10.749-54.364-28.22-4.694v-18.459l-35.27-7.718-7.057-.39z"
            ></path>
            <path
                fill="#fff"
                d="M213.544 269.154l-77.501-5.504 8.631-38.967 12.043-51.138-2.263-9.106 8.45.48 35.998 7.876v18.417l28.388 4.728-10.933 55.298-2.813 17.916zm-75.233-7.196l73.668 5.234 2.556-16.275 10.573-53.465-28.054-4.668V174.29l-34.476-7.542-5.732-.317 1.766 7.114-12.146 51.563-8.164 36.863.009-.013z"
            ></path>
            <path
                fill="#EEE"
                d="M76.04 148.035l-16.8 55.036 62.148 99.331 6.719-14.429v-9.397l4.369-1.006 4.703 4.698 2.689-4.026-2.689-15.436 8.399-37.921 12.091-51.345-2.015-8.107-17.133-.951-20.829-2.686-41.653-13.761z"
            ></path>
            <path
                fill="#fff"
                d="M121.508 304.334L58.233 203.205l17.193-56.343 42.472 14.027 20.709 2.668 17.78.985 2.234 8.986-12.147 51.563-8.356 37.729 2.718 15.62-3.515 5.268-5.137-5.131-3.151.728v8.865l-7.525 16.16v.004zm-61.26-101.395l61.02 97.532 5.913-12.699v-9.928l5.582-1.285 4.27 4.266 1.857-2.784-2.654-15.251.038-.18 8.4-37.922 12.043-51.139-1.796-7.229-16.52-.921-20.915-2.694-.085-.026-40.757-13.461-16.4 53.73.004-.009z"
            ></path>
            <path
                fill="#EEE"
                d="M18.93 133.945l-7.727 9.564v8.219L1.127 164.482l5.707 9.059-1.007 20.134 4.201 13.089v8.052l3.863 4.364v9.731l8.4 9.397-4.37 5.033 6.72 22.819 4.703 10.404-2.016 8.977 2.016 4.446 11.756 3.019 9.407 11.915 5.038 7.213h11.422l-1.677 5.037 6.046 7.045v15.436l46.356 4.026v-4.026l-3.691-10.737 3.691-1.679 1.681-5.705 10.414-7.131-8.399-11.997-62.148-99.331 16.799-55.036-23.517-8.725-33.593-5.366z"
            ></path>
            <path
                fill="#fff"
                d="M118.619 344.688l-48.21-4.189v-15.941l-6.165-7.182 1.44-4.313h-10.62l-5.299-7.59-9.197-11.645-11.884-3.054-2.324-5.131 2.015-8.977-4.622-10.241-6.86-23.303 4.167-4.802-8.09-9.054v-9.735l-3.863-4.364v-8.258L4.9 193.799l1.003-20.01L0 164.422l10.281-13.012v-8.228l8.267-10.236 34.3 5.5 24.332 9.028-16.928 55.465 61.912 98.954 8.918 12.742-10.911 7.473-1.707 5.795-3.314 1.504 3.473 10.09v5.187l-.004.004zm-46.357-5.88l44.509 3.867v-2.865l-3.919-11.384 4.073-1.851 1.655-5.615 9.917-6.788-7.889-11.273-62.37-99.686 16.665-54.604-22.612-8.39-32.976-5.268-7.186 8.895v8.215l-9.874 12.494 5.518 8.754-1.012 20.258 4.194 13.064v7.846l3.863 4.364v9.731l8.703 9.74-4.57 5.268 6.56 22.276 4.802 10.626-2.015 8.977 1.706 3.76 11.632 2.99 9.63 12.202 4.763 6.819h12.224l-1.92 5.752 5.929 6.908v14.931-.013z"
            ></path>
            <path
                fill="#EEE"
                d="M117.693 343.678l34.265 17.453 17.806 10.737 34.601 8.052 7.057-46.307 4.03-62.082-2.688-3.358-75.585-5.367 2.689 15.436-2.689 4.026-4.703-4.698-4.369 1.006v9.397l-6.719 14.43 8.4 11.996-10.415 7.131-1.68 5.705-3.692 1.679 3.692 10.738v4.026z"
            ></path>
            <path
                fill="#fff"
                d="M205.128 381.047l-35.715-8.313-.125-.077-17.776-10.721-34.742-17.693v-4.437l-3.918-11.384 4.073-1.85 1.655-5.615 9.917-6.789-8.168-11.671 6.856-14.725v-9.928l5.582-1.284 4.27 4.265 1.857-2.784-2.826-16.236 77.167 5.482 3.164 3.949-4.064 62.518-7.203 47.297-.004-.004zm-35.012-10.043l33.485 7.795 6.899-45.284 4.004-61.684-2.212-2.762-74.003-5.256 2.547 14.64-3.516 5.268-5.136-5.131-3.151.728v8.866l-6.582 14.133 8.627 12.327-10.912 7.474-1.706 5.794-3.315 1.504 3.473 10.091v3.614l33.82 17.226 17.678 10.665v-.008z"
            ></path>
            <g style={{ mixBlendMode: "multiply" }}>
                <path
                    fill="#FFD3B3"
                    d="M389.667 205.725c7.788-4.666 10.316-14.755 5.646-22.534-4.671-7.78-14.77-10.305-22.558-5.64-7.788 4.666-10.316 14.755-5.645 22.534 4.67 7.78 14.769 10.305 22.557 5.64z"
                ></path>
                <path
                    fill="#FED9BE"
                    d="M381.246 208.276c-.669 0-1.342-.039-2.015-.12a16.52 16.52 0 01-11.088-6.228 16.465 16.465 0 01-3.43-12.236 16.482 16.482 0 016.234-11.076 16.528 16.528 0 0112.25-3.431 16.518 16.518 0 0111.087 6.228 16.46 16.46 0 013.43 12.236 16.478 16.478 0 01-6.234 11.076 16.505 16.505 0 01-10.234 3.551zm-.064-32.851a16.153 16.153 0 00-10.012 3.474 16.13 16.13 0 00-6.101 10.836 16.114 16.114 0 003.357 11.97 16.153 16.153 0 0010.848 6.095 16.159 16.159 0 0011.983-3.353 16.131 16.131 0 006.101-10.836 16.115 16.115 0 00-3.357-11.971 16.153 16.153 0 00-10.847-6.095c-.66-.081-1.316-.12-1.972-.12z"
                ></path>
            </g>
            <path
                fill="#FB6C05"
                d="M374.982 190.921c.412-3.435 3.537-5.889 6.98-5.478a6.274 6.274 0 015.484 6.973c-.412 3.435-3.537 5.889-6.98 5.478a6.27 6.27 0 01-5.484-6.973z"
            ></path>
            <g style={{ mixBlendMode: "multiply" }}>
                <path
                    fill="#FFD3B3"
                    d="M242.296 112.654c8.346-3.575 12.211-13.231 8.632-21.569-3.579-8.337-13.246-12.197-21.592-8.622-8.346 3.575-12.211 13.232-8.632 21.569 3.579 8.337 13.246 12.198 21.592 8.622z"
                ></path>
                <path
                    fill="#FED9BE"
                    d="M235.831 114.165c-.66 0-1.325-.038-1.994-.119-9.098-1.093-15.615-9.376-14.521-18.469a16.478 16.478 0 016.234-11.076 16.526 16.526 0 0112.249-3.43c9.098 1.092 15.615 9.375 14.522 18.468-1.012 8.42-8.202 14.626-16.49 14.626zm-16.155-18.54c-1.068 8.891 5.303 16.994 14.204 18.061 8.901 1.066 17.013-5.298 18.085-14.19 1.068-8.891-5.304-16.995-14.205-18.061a16.157 16.157 0 00-11.983 3.353 16.128 16.128 0 00-6.101 10.836z"
                ></path>
            </g>
            <path
                fill="#FB6C05"
                d="M239.711 102.478a6.261 6.261 0 001.025-8.802 6.277 6.277 0 00-8.811-1.024 6.261 6.261 0 00-1.026 8.802 6.278 6.278 0 008.812 1.024z"
            ></path>
            <g style={{ mixBlendMode: "multiply" }}>
                <path
                    fill="#FFD3B3"
                    d="M791.125 96.249c14.594-14.579 14.594-38.215 0-52.794-14.594-14.578-38.255-14.578-52.849 0-14.594 14.579-14.594 38.215 0 52.794 14.594 14.578 38.255 14.578 52.849 0z"
                ></path>
                <path
                    fill="#FED9BE"
                    d="M764.657 107.574a37.62 37.62 0 01-23.032-7.842c-16.494-12.712-19.564-36.457-6.839-52.938 12.726-16.48 36.496-19.543 52.994-6.835 16.494 12.712 19.564 36.456 6.839 52.937-7.435 9.629-18.638 14.678-29.962 14.678zm.121-74.652c-1.595 0-3.199.103-4.807.308-9.792 1.264-18.505 6.258-24.533 14.066-12.447 16.12-9.445 39.347 6.693 51.78 16.138 12.434 39.389 9.436 51.836-6.685 12.447-16.121 9.445-39.348-6.693-51.781-6.534-5.033-14.38-7.692-22.496-7.692v.004z"
                ></path>
            </g>
            <path
                fill="#FB6C05"
                d="M765.503 76.004a6.267 6.267 0 10-1.607-12.429 6.267 6.267 0 101.607 12.429z"
            ></path>
            <g style={{ mixBlendMode: "multiply" }}>
                <path
                    fill="#FFD3B3"
                    d="M85.663 163.694c14.594-14.578 14.594-38.215 0-52.793-14.593-14.578-38.255-14.578-52.849 0-14.594 14.578-14.594 38.215 0 52.793 14.594 14.579 38.256 14.579 52.85 0z"
                ></path>
                <path
                    fill="#FED9BE"
                    d="M59.245 175.023c-9.763 0-19.521-3.744-26.904-11.222-7.088-7.178-10.95-16.682-10.882-26.764.069-10.082 4.064-19.531 11.25-26.61 7.186-7.08 16.679-10.943 26.793-10.87 10.093.068 19.551 4.06 26.638 11.238 14.629 14.815 14.467 38.761-.368 53.374-7.35 7.239-16.945 10.854-26.532 10.854h.005zm-.009-74.644c-9.775 0-18.98 3.769-25.948 10.634-7.027 6.926-10.937 16.169-11.006 26.032-.069 9.864 3.713 19.158 10.646 26.182 14.312 14.494 37.756 14.657 52.265.36s14.672-37.716.36-52.21c-6.933-7.019-16.185-10.925-26.06-10.994h-.261l.004-.004z"
                ></path>
            </g>
            <path
                fill="#FB6C05"
                d="M54.837 132.835c2.465-2.432 6.44-2.402 8.875.06a6.266 6.266 0 01-.06 8.866c-2.465 2.433-6.44 2.403-8.875-.06a6.266 6.266 0 01.06-8.866z"
            ></path>
            <g style={{ mixBlendMode: "multiply" }}>
                <path
                    fill="#FFD3B3"
                    d="M585.688 378.299c14.594-14.578 14.594-38.214 0-52.793-14.594-14.578-38.255-14.578-52.849 0-14.594 14.579-14.594 38.215 0 52.793 14.594 14.579 38.255 14.579 52.849 0z"
                ></path>
                <path
                    fill="#FED9BE"
                    d="M559.213 389.647c-9.176 0-17.931-3.285-24.885-9.389-7.58-6.655-12.117-15.86-12.768-25.92-.652-10.061 2.658-19.77 9.321-27.347 6.663-7.572 15.877-12.104 25.948-12.755 20.79-1.349 38.798 14.46 40.14 35.228 1.342 20.768-14.475 38.756-35.265 40.097-.832.056-1.664.081-2.491.081v.005zm2.439-.9c20.336-1.315 35.814-18.909 34.498-39.223-1.317-20.314-18.93-35.767-39.265-34.461-9.853.638-18.865 5.067-25.382 12.476-6.517 7.41-9.755 16.909-9.116 26.752.639 9.842 5.072 18.845 12.49 25.355 7.417 6.51 16.927 9.744 26.78 9.106l-.005-.005z"
                ></path>
            </g>
            <path
                fill="#FB6C05"
                d="M562.678 357.18a6.263 6.263 0 001.846-8.668 6.277 6.277 0 00-8.677-1.843 6.261 6.261 0 00-1.846 8.667 6.277 6.277 0 008.677 1.844z"
            ></path>
            <g style={{ mixBlendMode: "multiply" }}>
                <path
                    fill="#FFD3B3"
                    d="M466.388 235.37c20.639 0 37.37-16.713 37.37-37.33 0-20.617-16.731-37.331-37.37-37.331-20.639 0-37.37 16.714-37.37 37.331 0 20.617 16.731 37.33 37.37 37.33z"
                ></path>
                <path
                    fill="#FED9BE"
                    d="M466.306 235.789c-7.79 0-15.375-2.407-21.845-7.015-16.965-12.078-20.931-35.69-8.845-52.638 5.857-8.21 14.565-13.65 24.516-15.32 9.952-1.67 19.959.634 28.178 6.484 8.219 5.851 13.664 14.55 15.336 24.49 1.672 9.941-.634 19.938-6.491 28.148-5.857 8.211-14.565 13.65-24.516 15.32a38.132 38.132 0 01-6.333.531zm-30.017-59.177c-11.825 16.579-7.944 39.677 8.653 51.49 8.039 5.722 17.827 7.974 27.564 6.343 9.737-1.632 18.252-6.956 23.98-14.986 5.728-8.031 7.984-17.809 6.35-27.536-1.634-9.726-6.963-18.232-15.002-23.954-8.039-5.723-17.828-7.975-27.564-6.344-9.737 1.632-18.252 6.956-23.981 14.987z"
                ></path>
            </g>
            <path
                fill="#FB6C05"
                d="M461.277 194.403a6.28 6.28 0 018.751-1.469 6.265 6.265 0 011.47 8.742 6.28 6.28 0 01-8.75 1.469 6.265 6.265 0 01-1.471-8.742z"
            ></path>
            <g style={{ mixBlendMode: "multiply" }}>
                <path
                    fill="#FFD3B3"
                    d="M655.055 292.861c7.141-5.604 8.383-15.93 2.773-23.063-5.609-7.134-15.946-8.374-23.087-2.771-7.142 5.604-8.383 15.929-2.774 23.063 5.61 7.134 15.946 8.374 23.088 2.771z"
                ></path>
                <path
                    fill="#FED9BE"
                    d="M644.924 296.526a16.68 16.68 0 01-2.015-.12c-9.098-1.092-15.615-9.375-14.521-18.468a16.478 16.478 0 016.234-11.076 16.525 16.525 0 0112.249-3.43 16.523 16.523 0 0111.088 6.227 16.469 16.469 0 013.43 12.237 16.482 16.482 0 01-6.234 11.076 16.507 16.507 0 01-10.235 3.55l.004.004zm-16.176-18.545c-1.068 8.892 5.303 16.995 14.204 18.061a16.157 16.157 0 0011.984-3.353 16.13 16.13 0 006.101-10.836 16.117 16.117 0 00-3.357-11.971 16.157 16.157 0 00-10.848-6.095 16.16 16.16 0 00-11.983 3.354 16.127 16.127 0 00-6.101 10.836v.004z"
                ></path>
            </g>
            <path
                fill="#FB6C05"
                d="M647.97 285.417a6.261 6.261 0 002.394-8.532 6.275 6.275 0 00-8.541-2.392 6.262 6.262 0 00-2.395 8.532 6.276 6.276 0 008.542 2.392z"
            ></path>
            <g style={{ mixBlendMode: "multiply" }}>
                <path
                    fill="#FFD3B3"
                    d="M172.374 148.478c7.208-5.517 8.575-15.827 3.051-23.028-5.523-7.201-15.844-8.565-23.052-3.048-7.208 5.517-8.574 15.827-3.051 23.028 5.523 7.201 15.844 8.565 23.052 3.048z"
                ></path>
                <path
                    fill="#FED9BE"
                    d="M162.407 152.061c-.669 0-1.342-.039-2.016-.12a16.523 16.523 0 01-11.087-6.227 16.464 16.464 0 01-3.43-12.237 16.478 16.478 0 016.234-11.076 16.526 16.526 0 0112.249-3.43c9.099 1.092 15.616 9.375 14.522 18.468a16.478 16.478 0 01-6.234 11.076 16.504 16.504 0 01-10.234 3.55l-.004-.004zm-.065-32.851a16.15 16.15 0 00-10.011 3.474 16.13 16.13 0 00-6.101 10.836 16.117 16.117 0 003.357 11.971 16.16 16.16 0 0010.847 6.095 16.16 16.16 0 0011.984-3.354 16.13 16.13 0 006.101-10.836c1.068-8.891-5.304-16.995-14.204-18.061a15.986 15.986 0 00-1.973-.12v-.005z"
                ></path>
            </g>
            <path
                fill="#FB6C05"
                d="M168.457 136.993a6.265 6.265 0 00-4.547-7.609 6.275 6.275 0 00-7.617 4.543 6.273 6.273 0 0012.164 3.066z"
            ></path>
            <g style={{ mixBlendMode: "multiply" }}>
                <path
                    fill="#FFD3B3"
                    d="M651.926 185.077c8.905-1.777 14.681-10.43 12.902-19.325-1.779-8.896-10.441-14.666-19.346-12.888-8.905 1.777-14.681 10.429-12.902 19.325 1.78 8.895 10.441 14.666 19.346 12.888z"
                ></path>
                <path
                    fill="#FED9BE"
                    d="M648.736 185.58c-.669 0-1.342-.039-2.015-.12-9.098-1.092-15.615-9.376-14.522-18.468a16.478 16.478 0 016.234-11.076 16.528 16.528 0 0112.25-3.431 16.52 16.52 0 0111.087 6.228 16.46 16.46 0 013.43 12.236 16.478 16.478 0 01-6.234 11.076 16.504 16.504 0 01-10.234 3.55l.004.005zm-16.177-18.546c-1.067 8.892 5.304 16.995 14.205 18.062a16.16 16.16 0 0011.983-3.354 16.128 16.128 0 006.102-10.836 16.123 16.123 0 00-3.358-11.971 16.156 16.156 0 00-10.847-6.094 16.158 16.158 0 00-11.984 3.353 16.13 16.13 0 00-6.101 10.836v.004z"
                ></path>
            </g>
            <path
                fill="#FB6C05"
                d="M642.472 168.226c.412-3.435 3.537-5.889 6.98-5.478a6.273 6.273 0 015.484 6.972 6.277 6.277 0 01-6.98 5.478 6.272 6.272 0 01-5.484-6.972z"
            ></path>
            <g style={{ mixBlendMode: "multiply" }}>
                <path
                    fill="#FFD3B3"
                    d="M308.211 263.809c7.208-5.518 8.574-15.828 3.051-23.028-5.523-7.201-15.844-8.566-23.052-3.048-7.208 5.517-8.574 15.827-3.051 23.028 5.523 7.2 15.844 8.565 23.052 3.048z"
                ></path>
                <path
                    fill="#FED9BE"
                    d="M298.244 267.398c-.669 0-1.342-.038-2.015-.12a16.523 16.523 0 01-11.088-6.227 16.469 16.469 0 01-3.43-12.237 16.482 16.482 0 016.234-11.076 16.528 16.528 0 0112.25-3.43 16.52 16.52 0 0111.087 6.227 16.464 16.464 0 013.43 12.237 16.474 16.474 0 01-6.234 11.075 16.5 16.5 0 01-10.234 3.551zm-.064-32.85a16.152 16.152 0 00-10.012 3.473 16.13 16.13 0 00-6.101 10.836 16.117 16.117 0 003.357 11.971 16.16 16.16 0 0010.848 6.095 16.142 16.142 0 0011.983-3.354 16.128 16.128 0 006.102-10.836 16.123 16.123 0 00-3.358-11.971 16.156 16.156 0 00-10.847-6.094c-.66-.082-1.316-.12-1.972-.12z"
                ></path>
            </g>
            <path
                fill="#FB6C05"
                d="M291.98 250.043c.412-3.435 3.537-5.889 6.98-5.478a6.273 6.273 0 015.484 6.973c-.412 3.439-3.537 5.889-6.98 5.478a6.274 6.274 0 01-5.484-6.973z"
            ></path>
            <g style={{ mixBlendMode: "multiply" }}>
                <path
                    fill="#FFD3B3"
                    d="M163.202 320.834c8.725-2.517 13.755-11.622 11.235-20.338-2.52-8.715-11.635-13.739-20.36-11.222-8.724 2.517-13.754 11.623-11.234 20.338 2.52 8.715 11.635 13.739 20.359 11.222z"
                ></path>
                <path
                    fill="#FED9BE"
                    d="M158.656 321.676c-.669 0-1.342-.038-2.015-.12a16.523 16.523 0 01-11.088-6.227 16.464 16.464 0 01-3.43-12.237 16.474 16.474 0 016.234-11.075 16.523 16.523 0 0112.249-3.431 16.507 16.507 0 0111.088 6.227 16.464 16.464 0 013.43 12.237 16.478 16.478 0 01-6.234 11.076 16.504 16.504 0 01-10.234 3.55zm-.065-32.85a16.149 16.149 0 00-10.011 3.473 16.13 16.13 0 00-6.101 10.836 16.115 16.115 0 003.357 11.971 16.153 16.153 0 0010.847 6.095 16.143 16.143 0 0011.984-3.354 16.13 16.13 0 006.101-10.836 16.118 16.118 0 00-3.357-11.971 16.135 16.135 0 00-10.847-6.09 16.184 16.184 0 00-1.973-.12v-.004z"
                ></path>
            </g>
            <path
                fill="#FB6C05"
                d="M161.126 310.804a6.263 6.263 0 003.266-8.239 6.276 6.276 0 00-8.248-3.262 6.264 6.264 0 00-3.266 8.239 6.275 6.275 0 008.248 3.262z"
            ></path>
            <g style={{ mixBlendMode: "multiply" }}>
                <path
                    fill="#FFD3B3"
                    d="M257.401 188.063c7.09-5.669 8.236-16.006 2.56-23.088-5.675-7.081-16.023-8.226-23.112-2.557-7.089 5.67-8.235 16.006-2.56 23.088 5.676 7.082 16.023 8.226 23.112 2.557z"
                ></path>
                <path
                    fill="#FED9BE"
                    d="M247.137 191.851c-.661 0-1.325-.039-1.994-.12-9.098-1.092-15.615-9.375-14.522-18.468 1.094-9.089 9.386-15.595 18.484-14.507 9.098 1.093 15.615 9.376 14.522 18.469-1.012 8.42-8.203 14.626-16.49 14.626zm-16.156-18.541c-1.067 8.891 5.304 16.995 14.205 18.061 8.901 1.067 17.013-5.298 18.085-14.189 1.067-8.892-5.304-16.995-14.205-18.062-8.905-1.07-17.013 5.298-18.085 14.19z"
                ></path>
            </g>
            <path
                fill="#FB6C05"
                d="M240.894 174.496c.412-3.435 3.537-5.889 6.98-5.478a6.273 6.273 0 015.484 6.973c-.412 3.435-3.537 5.889-6.98 5.478a6.274 6.274 0 01-5.484-6.973z"
            ></path>
            <g style={{ mixBlendMode: "multiply" }}>
                <path
                    fill="#FFD3B3"
                    d="M679.358 478.354c8.331-3.609 12.157-13.281 8.545-21.604-3.613-8.323-13.295-12.144-21.627-8.536-8.331 3.609-12.157 13.281-8.545 21.604 3.612 8.323 13.295 12.144 21.627 8.536z"
                ></path>
                <path
                    fill="#FED9BE"
                    d="M672.84 479.95c-.668 0-1.342-.039-2.015-.12a16.504 16.504 0 01-11.087-6.228 16.46 16.46 0 01-3.43-12.236 16.478 16.478 0 016.234-11.076 16.526 16.526 0 0112.249-3.43 16.523 16.523 0 0111.088 6.227 16.466 16.466 0 013.43 12.236 16.482 16.482 0 01-6.234 11.076 16.502 16.502 0 01-10.235 3.551zm-16.176-18.545a16.114 16.114 0 003.357 11.97 16.133 16.133 0 0010.847 6.091 16.144 16.144 0 0011.984-3.354 16.13 16.13 0 006.101-10.836 16.118 16.118 0 00-3.357-11.971 16.156 16.156 0 00-10.848-6.094 16.16 16.16 0 00-11.983 3.353 16.13 16.13 0 00-6.101 10.836v.005z"
                ></path>
            </g>
            <path
                fill="#FB6C05"
                d="M676.681 468.292a6.262 6.262 0 001.058-8.798 6.277 6.277 0 00-8.808-1.057 6.261 6.261 0 00-1.058 8.798 6.279 6.279 0 008.808 1.057z"
            ></path>
            <g style={{ mixBlendMode: "multiply" }}>
                <path
                    fill="#FFD3B3"
                    d="M391.485 392.737c7.098-5.659 8.26-15.994 2.596-23.084-5.665-7.09-16.01-8.252-23.108-2.593-7.098 5.658-8.261 15.993-2.596 23.083 5.664 7.091 16.01 8.252 23.108 2.594z"
                ></path>
                <path
                    fill="#FED9BE"
                    d="M381.263 396.53c-.669 0-1.342-.038-2.015-.12a16.507 16.507 0 01-11.088-6.227 16.469 16.469 0 01-3.43-12.237c1.094-9.088 9.386-15.598 18.484-14.506a16.52 16.52 0 0111.087 6.227 16.464 16.464 0 013.43 12.237 16.478 16.478 0 01-6.234 11.076 16.504 16.504 0 01-10.234 3.55zm-16.357-18.567l.18.022a16.118 16.118 0 003.357 11.971 16.137 16.137 0 0010.848 6.09 16.137 16.137 0 0011.983-3.353 16.133 16.133 0 006.102-10.836 16.12 16.12 0 00-3.358-11.971 16.153 16.153 0 00-10.847-6.095c-8.901-1.071-17.013 5.298-18.085 14.19l-.18-.022v.004z"
                ></path>
            </g>
            <path
                fill="#FB6C05"
                d="M383.728 385.67a6.264 6.264 0 003.248-8.246 6.276 6.276 0 00-8.255-3.245 6.263 6.263 0 00-3.248 8.246 6.275 6.275 0 008.255 3.245z"
            ></path>
        </svg>
    );
}

export default MainImg;
