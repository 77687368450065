import React from "react";

function CustomizableWarrantyOptionsIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            fill="none"
            viewBox="0 0 52 52"
        >
            <rect
                width="50.5"
                height="50.5"
                x="0.75"
                y="0.75"
                stroke="#000"
                strokeWidth="1.5"
                rx="15.25"
            ></rect>
            <path
                fill="#000"
                d="M15.901 18.23h10.476a3.33 3.33 0 100-1.801H15.9a.901.901 0 000 1.802zm13.681-2.418a1.554 1.554 0 11-1.553 1.528 1.528 1.528 0 011.553-1.528zM15.901 26.552h2.78a3.33 3.33 0 100-1.802h-2.78a.9.9 0 100 1.802zm5.997-2.454a1.553 1.553 0 11.051 3.106 1.553 1.553 0 01-.051-3.106zM29.582 30.643a3.33 3.33 0 00-3.205 2.429H15.9a.901.901 0 000 1.802h10.476a3.33 3.33 0 103.205-4.23zm0 4.858a1.553 1.553 0 111.528-1.554 1.553 1.553 0 01-1.528 1.543v.01zM33.653 18.23h1.926a.901.901 0 100-1.801h-1.916a4.172 4.172 0 010 1.802h-.01zM35.579 24.75h-9.606c.065.296.098.598.098.901 0 .304-.034.606-.103.901h9.61a.901.901 0 100-1.802zM35.579 33.072h-1.916a4.172 4.172 0 010 1.802h1.916a.901.901 0 100-1.802z"
            ></path>
        </svg>
    );
}

export default CustomizableWarrantyOptionsIcon;
