import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import DedicatedHomeWarrantyServicesIcon from "./icons/dedicatedHomeWarrantyServicesIcon";
import SeamlessUserExperienceIcon from "./icons/seamlessUserExperienceIcon";
import DataDrivenInsightsIcon from "./icons/dataDrivenInsightsIcon";
const benefitsList = [
    {
        title: "Dedicated to Home Warranty Services:",
        description:
            "Unlike generic comparison sites, we focus exclusively on the home warranty industry. Our platform is tailored to meet the needs of both providers and homeowners",
        icon: <DedicatedHomeWarrantyServicesIcon />,
    },
    {
        title: "Seamless User Experience:",
        description:
            "Our user-friendly interface ensures homeowners can navigate and find the right warranty plan without hassle",
        icon: <SeamlessUserExperienceIcon />,
    },
    {
        title: "Data-Driven Insights:",
        description:
            "Access performance analytics for your plans, offering valuable insights to refine your offerings and increase conversions",
        icon: <DataDrivenInsightsIcon />,
    },
];

export default function Benefits(): ReactElement {
    return (
        <section className={`${styles["bg-style"]}`}>
            <div className="pt-16 lg:pt-32 pb-20 lg:pb-40">
                <h2 className="font-extrabold text-[25px] lg:text-[30px] text-center mb-8 lg:mb-16">
                    What Sets Us Apart
                </h2>
                <div className="flex flex-wrap gap-6 justify-center">
                    {benefitsList.map((el) => (
                        <div key={el.title} className={styles["item"]}>
                            <span>{el.icon}</span>
                            <h3 className="font-extrabold text-base lg:text-[20px] mt-2 lg:mt-5">
                                {el.title}
                            </h3>
                            <p className="font-light text-sm lg:text-lg mt-2">
                                {el.description}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
