import React from "react";

function GenerateMoreLeadsIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            fill="none"
            viewBox="0 0 52 52"
        >
            <rect
                width="50.5"
                height="50.5"
                x="0.75"
                y="0.75"
                stroke="#000"
                strokeWidth="1.5"
                rx="15.25"
            ></rect>
            <path
                fill="#000"
                d="M32.337 23.295c.073.152.144.305.21.461l-.082-.197c.311.74.524 1.516.633 2.31l-.03-.218c.11.811.11 1.63.003 2.441l.03-.218c-.11.795-.32 1.57-.628 2.313l.082-.197a9.117 9.117 0 01-1.125 1.95c-.085.115.117-.15-.006.005l-.106.132a9.199 9.199 0 01-1.335 1.316l-.063.049c-.099.08.07-.055.07-.055-.078.069-.168.126-.253.189a9.05 9.05 0 01-1.718.96c.066-.026.131-.054.197-.081a9.333 9.333 0 01-2.33.639l.219-.03a9.325 9.325 0 01-2.436 0l.219.03a9.108 9.108 0 01-2.33-.639l.197.082a9.015 9.015 0 01-1.81-1.03c-.044-.03-.085-.062-.126-.095-.044-.033-.112-.096.07.055-.024-.022-.051-.041-.075-.06a9.244 9.244 0 01-1.442-1.442c-.02-.025-.041-.05-.06-.077.008.011.12.161.04.052-.032-.044-.065-.09-.1-.134-.074-.104-.148-.207-.219-.311a9.242 9.242 0 01-.806-1.502l.082.197a9.272 9.272 0 01-.628-2.313l.03.218a9.437 9.437 0 01-.002-2.403l-.03.219c.109-.811.325-1.6.641-2.354l-.082.196a8.932 8.932 0 011.065-1.857c.039-.052.175-.199.011-.016.036-.038.066-.085.101-.126.082-.1.167-.199.254-.297a8.878 8.878 0 011.207-1.136c.077-.06-.15.114-.07.054.021-.016.04-.032.062-.046l.142-.104a9.022 9.022 0 011.748-.983l-.197.082a9.333 9.333 0 012.33-.639l-.219.03a9.324 9.324 0 012.436 0l-.219-.03a9.1 9.1 0 012.33.64l-.197-.083a9.023 9.023 0 011.922 1.114.987.987 0 01-.057-.043c.055.054.126.098.186.147.226.188.445.385.652.593.303.303.858.327 1.158 0 .3-.328.325-.836 0-1.158a9.94 9.94 0 00-4.525-2.586 10.048 10.048 0 00-5.09.038 9.914 9.914 0 00-4.51 2.682c-1.383 1.439-2.335 3.301-2.636 5.278a10.36 10.36 0 00.025 3.323 9.713 9.713 0 001.086 3.072 9.905 9.905 0 004.1 3.98 9.965 9.965 0 004.983 1.097c1.726-.082 3.435-.604 4.89-1.54a9.854 9.854 0 002.125-1.83 10.211 10.211 0 001.75-2.87c.825-2.029.915-4.366.293-6.46a10.627 10.627 0 00-.634-1.604c-.188-.385-.767-.521-1.12-.295-.384.249-.486.702-.283 1.115z"
            ></path>
            <path
                fill="#000"
                d="M36.889 37.72c-.566-.564-1.128-1.127-1.693-1.692l-2.687-2.687-.618-.617c-.303-.303-.86-.325-1.157 0-.3.327-.325.835 0 1.158l1.693 1.693 2.687 2.686.617.618c.303.303.86.325 1.158 0 .3-.328.325-.836 0-1.158zM17.4 31.612l1.968-1.974c1.046-1.049 2.095-2.097 3.14-3.149.241-.243.484-.483.724-.726h-1.157l1.37 1.37 2.185 2.186.497.497a.83.83 0 001.158 0l1.043-1.044 2.504-2.504 3.028-3.028 2.616-2.616c.42-.42.852-.836 1.267-1.267l.02-.02c.303-.302.324-.86 0-1.157-.328-.3-.836-.325-1.158 0l-1.043 1.043-2.505 2.504-3.028 3.029-2.616 2.616c-.42.42-.855.832-1.267 1.267l-.02.019h1.159l-1.371-1.371-2.185-2.185-.497-.497a.832.832 0 00-1.157 0l-1.97 1.975c-1.045 1.048-2.094 2.097-3.14 3.148-.24.243-.483.483-.723.726-.303.304-.325.86 0 1.158.327.3.835.325 1.157 0z"
            ></path>
            <path
                fill="#000"
                d="M33.76 19.584H37.18l-.82-.82v3.422c0 .429.378.838.82.82.445-.02.82-.361.82-.82V19.19v-.426a.83.83 0 00-.82-.819h-3.422c-.428 0-.838.377-.819.82.022.442.36.819.82.819z"
            ></path>
        </svg>
    );
}

export default GenerateMoreLeadsIcon;
