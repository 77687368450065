import ArrowRightIcon from "@components/svg/arrowRightIcon";
import { ReactElement, ReactNode } from "react";
import styles from "./style.module.scss";
import { useModalContext } from "@components/torts/modalContext";

export default function SharedButton({
    scroll,
    children,
}: {
    children: ReactNode;
    scroll?: boolean;
}): ReactElement {
    const { setModalOpen } = useModalContext();
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    return (
        <button
            className={styles["buttonStyle"]}
            onClick={() => {
                if (scroll) {
                    scrollToTop();
                } else {
                    setModalOpen(true);
                }
            }}
        >
            <span className="block">{children}</span>
            <span className={`${styles["arrow"]}`}>
                <ArrowRightIcon />
            </span>
        </button>
    );
}
