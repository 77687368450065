import React from "react";

function ProactiveCustomerEngagementIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            fill="none"
            viewBox="0 0 52 52"
        >
            <rect
                width="50.5"
                height="50.5"
                x="0.75"
                y="0.75"
                stroke="#000"
                strokeWidth="1.5"
                rx="15.25"
            ></rect>
            <path
                fill="#000"
                d="M18.063 31.506c0 4.605 3.746 8.351 8.35 8.351 9.106-.05 11.542-12.65 3.146-16.088.004-.002 4.938-6.7 4.941-6.702a.437.437 0 00.04-.438c.005-.074-2.873-4.337-2.884-4.405a.435.435 0 00-.36-.195l-3.313-.01a.404.404 0 00-.084 0L21.561 12a.423.423 0 00-.361.193c-.003.005-2.882 4.335-2.884 4.34a.434.434 0 00.012.487c-.001.002 4.918 6.756 4.917 6.759a8.333 8.333 0 00-5.181 7.727h-.001zm10.64-8.032a8.063 8.063 0 00-.704-.168l1.753-6.072 3.542.01-4.592 6.23zm-1.57-.287a8.471 8.471 0 00-1.476.004l-1.683-5.974 4.879.015-1.72 5.955zm3.927-10.294l2.286 3.487-3.61-.011-1.19-3.483 2.514.008zm-3.43-.01l1.191 3.483-4.81-.015 1.213-3.474 2.406.007zm-5.839-.017l2.517.007-1.214 3.475-3.61-.011 2.307-3.47zm-2.26 4.337l3.543.01 1.719 6.1a8.526 8.526 0 00-.695.168l-4.567-6.278zm4.531 7.195a7.509 7.509 0 014.672-.01c8.013 2.67 6.13 14.534-2.32 14.604-8.433-.072-10.334-11.89-2.352-14.593v-.001z"
            ></path>
            <path
                fill="#000"
                d="M21.157 28.392a.433.433 0 00.602-.11c.247-.356.535-.684.857-.975a.433.433 0 00-.582-.642 6.59 6.59 0 00-.986 1.124.433.433 0 00.109.602z"
            ></path>
            <path
                fill="#000"
                d="M26.414 38.033c5.394.103 8.543-6.545 5.058-10.652-1.53-1.9-4.116-2.795-6.49-2.244a6.52 6.52 0 00-1.51.54c-.503.266-.123 1.02.39.773a5.674 5.674 0 016.94 1.478c3.02 3.561.292 9.33-4.388 9.239-3.873.072-6.718-4.049-5.305-7.644.191-.536-.604-.831-.81-.303-1.628 4.145 1.65 8.896 6.115 8.812zM16.234 16.86a.433.433 0 00-.433-.432h-1.368a.433.433 0 000 .865H15.8a.433.433 0 00.433-.433zM15.661 20.905c.199.052 1.191-.799 1.36-.887a.433.433 0 10-.508-.7l-1.107.804c-.34.23-.147.799.255.783zM17.021 13.675l-1.107-.805a.433.433 0 10-.508.7l1.107.805c.464.326.964-.357.508-.7zM38.827 16.86a.433.433 0 00-.432-.432h-1.37a.433.433 0 000 .865h1.37a.433.433 0 00.432-.433zM35.807 20.02l1.107.803c.463.326.964-.357.508-.7l-1.107-.804c-.46-.33-.965.366-.508.7zM36.061 14.457c.2.052 1.192-.8 1.361-.886a.433.433 0 10-.508-.7l-1.107.803c-.34.23-.148.799.254.783z"
            ></path>
            <path
                fill="#000"
                d="M24.669 33.344c-.387-.413-1.018.172-.634.589a3.405 3.405 0 001.947 1.048c.017.377-.127.98.433 1.008.55-.029.42-.605.432-.98 2.586-.442 2.89-3.22 0-4.013v-2.12c.828.11 1.286.566 1.306.586.392.407 1.015-.186.626-.597-.03-.033-.716-.736-1.93-.86-.014-.375.116-.951-.434-.98-.561.028-.415.638-.432 1.015-1.034.222-1.702 1.096-1.702 1.896 0 1.1.97 1.492 1.702 1.71V34.1a2.548 2.548 0 01-1.313-.756h-.001zm2.178-1.434c1.614.534 1.555 1.826 0 2.225V31.91zm-1.702-1.975c0-.417.363-.818.836-.992v1.793c-.507-.174-.836-.385-.836-.801z"
            ></path>
        </svg>
    );
}

export default ProactiveCustomerEngagementIcon;
