import React from "react";

function ExpandedMarketReachIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            fill="none"
            viewBox="0 0 52 52"
        >
            <rect
                width="50.5"
                height="50.5"
                x="0.75"
                y="0.75"
                stroke="#000"
                strokeWidth="1.5"
                rx="15.25"
            ></rect>
            <path
                fill="#000"
                d="M16.713 16a.714.714 0 00-.706.818v-.004 5.63a.714.714 0 001.427 0V18.446l4.493 4.493a.714.714 0 001.009-1.01l-4.493-4.494h3.997a.714.714 0 000-1.427h-5.633a.627.627 0 00-.095-.007l.001-.001zm18.553 0a.672.672 0 00-.08.008l.005-.001h-5.632a.714.714 0 100 1.428h3.997l-4.492 4.494a.714.714 0 101.01 1.01l4.492-4.494V22.443a.714.714 0 101.428 0v-5.635a.714.714 0 00-.707-.808h-.022.001zM16.71 28.85a.714.714 0 00-.704.714v5.726c0 .021.002.042.003.062v-.003.011-.002l.008.058v-.005a.77.77 0 00.004.024v-.004c.015.07.038.131.068.188l-.002-.004.01.02-.001-.005c.01.02.019.034.029.05l-.002-.003a.185.185 0 00.01.016l-.001-.002c.04.056.085.106.137.148l.002.001.006.006a.71.71 0 00.425.153H22.44a.714.714 0 100-1.428h-3.997l4.492-4.493a.714.714 0 00-.498-1.225h-.022.002a.712.712 0 00-.49.215l-4.493 4.494v-3.989-.01a.714.714 0 00-.714-.713h-.011.001zm18.56 0a.714.714 0 00-.704.714v3.999l-4.492-4.494a.715.715 0 10-1.01 1.009l4.492 4.494H29.56a.714.714 0 100 1.428h5.72a.708.708 0 00.216-.034l-.005.002a.468.468 0 00.006-.003l-.004.002a.664.664 0 00.07-.026l-.004.002a.367.367 0 00.009-.004l-.004.002a.777.777 0 00.063-.031l-.003.002a.02.02 0 00.004-.003l-.002.001a.749.749 0 00.098-.064h-.002a.569.569 0 00.028-.022h-.001a.575.575 0 00.044-.04l.002-.003a.67.67 0 00.084-.107l.002-.003-.002.003.003-.004a.638.638 0 00.033-.056l.002-.004-.001.003.002-.004a.661.661 0 00.049-.127l.001-.005v.002-.005a.69.69 0 00.025-.17v-5.73-.009a.714.714 0 00-.713-.714h-.011.001z"
            ></path>
        </svg>
    );
}

export default ExpandedMarketReachIcon;
