import React from "react";

function DataDrivenInsightsIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="78"
            height="78"
            fill="none"
            viewBox="0 0 78 78"
        >
            <path
                fill="#000"
                d="M15.275 48.263L3.412 60.125c-1.787 1.625-1.787 4.55 0 6.175 1.788 1.625 4.55 1.787 6.175 0L21.45 54.437l-6.175-6.174z"
            ></path>
            <path
                fill="#000"
                d="M69.55 35.75c-.487-.975-.65-2.112-.487-3.25.162-.975.162-1.95.162-2.925 0-7.962-3.25-15.438-8.938-20.8C54.437 3.25 46.8.325 38.837.812c-13.975.813-25.675 11.863-26.975 26-.487 5.038.325 10.238 2.438 14.788.325.65.487 1.137.813 1.788l.162-.163 4.388 4.387 2.437-2.437c-8.45-9.587-7.963-24.05 1.137-33.313a24.35 24.35 0 0117.226-7.15c6.5 0 12.675 2.6 17.224 7.15a24.421 24.421 0 010 34.613 24.35 24.35 0 01-17.224 7.15c-6.013 0-11.538-2.112-15.925-6.013L22.1 50.05l4.387 4.388-7.312 7.312c0 .975.162 2.112.162 3.25v12.188H50.7v-14.95h9.75c2.112 0 3.737-1.626 3.737-3.738v-9.75H76.05l-6.5-13z"
            ></path>
            <path
                fill="#000"
                d="M41.437 30.062L36.4 25.025 25.025 36.562 27.462 39l8.775-8.938 5.038 5.038L51.35 25.025v4.712h3.575V19.012H44.2v3.575h4.712l-7.475 7.475z"
            ></path>
        </svg>
    );
}

export default DataDrivenInsightsIcon;
