import React, { ReactElement } from "react";
import Image from "next/image";
import SharedButton from "../sharedButton";

export default function ReadyToJoin({
    scrollButton,
}: {
    scrollButton?: boolean;
}): ReactElement {
    return (
        <section
            className="flex flex-col lg:flex-row mt-40 lg:mt-80 mb-40 lg:mb-72  rounded-2xl px-5 lg:px-0 pb-14 lg:pb-0 gap-10 lg:gap-0"
            style={{ backgroundColor: "#F3F3F3" }}
        >
            <div className="max-w-[411px]   lg:mt-[70px] mx-auto lg:mx-0 lg:ml-20 lg:mb-14 order-2 lg:order-1 ">
                <h2 className="font-extrabold text-[25px] lg:text-[40px]">
                    Ready to Join?
                </h2>
                <p className="font-light text-sm lg:text-xl mt-3">
                    Contact us today to learn how we can help you grow and
                    connect with homeowners seeking reliable home warranty
                    solutions.
                </p>
                <div className="mt-5 lg:mt-10">
                    <SharedButton scroll={scrollButton}>
                        Schedule a Call
                    </SharedButton>
                </div>
            </div>
            <div className="relative w-[295px] h-[217px] lg:w-[478px] lg:h-[351px]  mx-auto lg:mx-0 lg:ml-36  -mt-10 order-1 lg:order-2">
                <Image
                    src={"/premiumcoverage/assets/images/readyToJoinImg.png"}
                    alt="Ready to Join Image"
                    layout="fill"
                />
            </div>
        </section>
    );
}
